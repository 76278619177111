import React from 'react'
import "./QuePuedoHacerDenthereum.css"
import SubtituloDenthereum from '../SubtituloDenthereum'
import Logo from '../../assets/images/BasicLogoDenthereumAmarillo.png';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

export default function QuePuedoHacerDenthereum () {

    const state= {
        responsive:{
            0: {
                items: 1,
                stagePadding: 20
            },
            576: {
                items: 2,
                stagePadding: 20
            },
            768: {
                items: 2,
                stagePadding: 30
            },
            992: {
                items: 3,
                stagePadding: 35
            },
            1200: {
                items: 3,
                stagePadding: 35
            },
        },
    }

    return(

        <div className="container-fluid noPadding">

            <div className="container quePuedoHacer__container mt-5">

                <SubtituloDenthereum text="¿QUÉ PUEDO HACER CON MIS DTH?" estilo="infoDenthereum__subtitulo2" />

                <OwlCarousel className="owl-theme"
                    margin={10}
                    autoplay={false}
                    loop={true}
                    dots={true}
                    touchDrag={true}
                    animateIn={true}
                    responsive={state.responsive}
                    center={false}
                    lazyLoad={true}
                >
                    <div className="item" key={1}>
                        <div className="infoDenthereum__cardCurso">

                            <div className="header__cardCurso">

                                <img src="https://cursodontic.com/asset/img/imagenesCurso/porcelana_sobre_platino.jpg" alt="" className="cardCurso__imgCard" />

                            </div>

                            <div className="body__cardCurso">

                                <p className="cardCurso__textTitulo">
                                                
                                    Cursos en línea
                                            
                                </p>

                                <p className="cardCurso__textSubtitulo">
                                    Accede a cualquier curso en la plataforma con un 10% de descuento pagando con tus Denthereum
                                </p>

                                <Link to='/cursos' className="detallesCurso__boton btn-block mt-3 w-100">

                                    <p className="boton__texto">VER CURSOS</p>

                                </Link>

                            </div>

                            </div>
                    </div>
                    <div className="item" key={2}>
                        <div className="infoDenthereum__cardCurso">

                            <div className="header__cardCurso">

                                <img src="https://cursodontic.com/asset/img/tiendas/proplay/PROTECTORES_MIXTO.jpg" alt="" className="cardCurso__imgCard" />

                            </div>

                            <div className="body__cardCurso">

                                <p className="cardCurso__textTitulo">
                                                
                                    Materiales
                                            
                                </p>

                                <p className="cardCurso__textSubtitulo">
                                    Compra materiales con Denthereum y obtienes un 5% de descuento.
                                </p>

                                <Link to='/materiales' className="detallesCurso__boton btn-block mt-3 w-100">

                                    <p className="boton__texto">VER MATERIALES</p>

                                </Link>

                            </div>

                            </div>
                    </div>
                    <div className="item" key={3}>
                        <div className="infoDenthereum__cardCurso">

                            <div className="header__cardCurso">

                                <img src="https://cursodontic.com/asset/img/imagenesCurso/estrategias_cobranza_exitosa.jpg" alt="" className="cardCurso__imgCard" />

                            </div>

                            <div className="body__cardCurso">

                                <p className="cardCurso__textTitulo">
                                                
                                    Invierte
                                            
                                </p>

                                <p className="cardCurso__textSubtitulo">
                                    Mantener como inversión nuestra criptomoneda podría generarte beneficios a largo plazo.
                                </p>

                                <Link to='/denthereum/comprar' className="detallesCurso__boton btn-block mt-3 w-100">

                                    <p className="boton__texto">INVERTIR EN DENTHEREUM</p>

                                </Link>

                            </div>

                            </div>
                    </div>
                </OwlCarousel>

            </div>

        </div>
    )
}