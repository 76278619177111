import React, { useState,useEffect }from 'react';
import './certificate.css';

import CourseProgress from "../courseProgress/CourseProgress";
import { MdDownloadForOffline } from 'react-icons/md';
import getLastClass from '../../services/getLastClass';
import getUserCertificates from '../../services/getCertificate';
import addViewedCourse from "../../services/addViewedCourse";
import { Link } from "react-router-dom";

// import config from '../config';

const Certificate = ({idCurso,fotoCurso,promedioVisto,tituloCurso,urlCurso}) => {

    let idUsuario = localStorage.getItem('idUsuario');

    const [nombreCursoUrl, setNombreCursoUrl] = useState();
    const [lastClass, setLastClass] = useState(1);
    const [certificate, setCertificate] = useState([]);

    useEffect(() => {
        getLastCourseData(idUsuario,idCurso);

        let url = urlCurso.split('/');
        setNombreCursoUrl(url[3]);

        getCertificatePDF(idUsuario,idCurso)
    },[]);

    function getLastCourseData(idUsuario,idCurso) {

        let formData = new FormData();

        formData.append('idUsuario', idUsuario);
        formData.append('idCurso', idCurso);
    
        getLastClass(formData)
        .then(response => {
          let _respuesta = JSON.parse(response);

          if(_respuesta.response === 'success'){
            setLastClass(_respuesta.lastClass);
          }
        })
        .catch(error => {
            console.log(error);
        })
    }

    function getCertificatePDF(idUsuario,idCurso) {
            
        let formData = new FormData();

        formData.append('idUsuario', idUsuario);
        formData.append('idCurso', idCurso);
    
        getUserCertificates(formData)
        .then(response => {
            let _respuesta = JSON.parse(response);

            if(_respuesta.response === 'success'){
                setCertificate(_respuesta.certificado);
            }
        })
        .catch(error => {
            console.log(error);
        })
    }

    function addLastViewedCourse(idCurso) {
        let formData = new FormData();
    
        formData.append('idUsuario', idUsuario);
        formData.append('idCurso', idCurso);
        formData.append("accion", 'insertarCursoVisto');
    
        addViewedCourse(formData)
        .then((responseAdd) => {
          let _respuestaAdd = JSON.parse(responseAdd);
        })
    }

    const saveFile = (urlPDF) => {
        console.log('http://localhost:8888/'+urlPDF,{
            'type': 'application/pdf'
        });

        fetch('http://localhost:8888/'+urlPDF)
        .then(resp => resp.arrayBuffer())
        .then(resp => {

            const file = new Blob([resp], {type: 'application/pdf'});

            const fileURL = URL.createObjectURL(file);
            const link = document.createElement('a');
            link.href = fileURL;
            link.download = "FileName" + new Date() + ".pdf";
            link.click();
        });
    };

  return (promedioVisto < 1 ? 
    <div className="col-12 text-center mt-3" key={idCurso+'not'}>
        <img className="certificate-course-image" src={fotoCurso}/>
        <div className="mt-3">
            <p className="fs-5">{tituloCurso}.</p>
        </div>
        <div className="py-2">
            <CourseProgress progress={promedioVisto} />
        </div>
        <div className="mt-2">
            <p className="font-medium text-strong-gray-color fs-6">¡Ya estás cerca de conseguirlo!</p>
        </div>
        <div className="mt-3" onClick={addLastViewedCourse(idCurso)}>
            <Link to={`/mis-cursos/${nombreCursoUrl}/${lastClass}`}>
                <button className="btn certificate-continue-button">
                    Continua aprendiendo
                </button>
            </Link>
        </div>
        <hr/>
    </div>
    :
    <div className="col-12 text-center mt-3" key={idCurso+'finished'}>
        <img className="certificate-course-image" src={fotoCurso}/>
        <div className="mt-3">
            <p className="fs-5">{tituloCurso}.</p>
        </div>
        <div className="py-2">
            <CourseProgress progress={promedioVisto} />
        </div>
        <div className="mt-2">
            <p className="font-medium text-strong-gray-color fs-6">¡Felicidades por haber finalizado el curso!</p>
        </div>
        {/* <div className="mt-3" onClick={() => saveFile(`${certificate['urlCertificado']}`)}> */}
        {/* <div className="mt-3" onClick={() => saveFile(`${certificate['urlCertificado']}`)}> */}
            {/* <Link to={`localhost:8888/${certificate['urlCertificado']}`} target="_blank" download> */}
            <Link to={`/${certificate['urlCertificado']}`} target="_blank" download>
                <MdDownloadForOffline className="text-aqua-color fs-1"/>
                <p className="fs-6">Descarga tu certificado</p>
            </Link>
        {/* </div> */}
        <hr/>
    </div>
  )
}

export default Certificate;