import React from 'react';
import { useState } from 'react';

import './CrearCuenta.css';

import loginSocialMedia from "./../../services/loginSocialMedia";
import SignUpSocialMedia from "./../../services/SignUpSocialMedia";
import loginEmail from "./../../services/loginEmail";
import registroCheckout from "./../../services/registroCheckout";

import Swal from 'sweetalert2'
import $ from 'jquery';

//FIREBASE
import  firebase  from '../../utils/Firebase/firebase.js';
import { sendCompleteRegistration } from '../../utils/facebookPixelEvents';

function CrearCuenta({siguientePaso, setLogin}){

    const auth = firebase.auth
    const provider = new firebase.auth.GoogleAuthProvider();
    const providerFB = new firebase.auth.FacebookAuthProvider();

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    let values = new FormData();

    const loginGoogle = () => {
        auth().signInWithPopup(provider)
          .then(({ user }) => {

            Swal.showLoading();

            values.append("uid",user.uid);
            values.append("tipoLogin", "google");
            values.append("fotoPerfilRedes", user.photoURL);

            let objectValues = Object.fromEntries(values);

            loginSocialMedia({values:objectValues}).then((response) => {
                console.log(response)
                let _response = JSON.parse(response);
                if(_response.response === "error"){

                    values.append("nombreUsuario", user.displayName);
                    values.append("correo", user.email);
                    values.append("especialidad", "sin especialidad");
                    values.append("medioRegistro", "web")
                    values.append("fotoPerfilRedes", user.photoURL);

                    objectValues = Object.fromEntries(values);

                    console.log(objectValues)

                    SignUpSocialMedia({values:objectValues}).then((respuesta) => {
                        console.log(respuesta)
                        let _respuesta = JSON.parse(respuesta);
                        if(_respuesta.response === "error"){
        
                            Swal.fire({
                                icon: 'error',
                                title: 'La cuenta ha sido desactivada o hubo un error',
                                text: 'Inténtalo de nuevo, si el problema persiste contáctanos'
                            })
        
                        } else {
                            try{
                                _respuesta.email = user.email;
                                handleLogin(_respuesta);
                                sendCompleteRegistration(_respuesta.nombre,_respuesta.correo,null);
                            } catch (err){
                
                            }
        
                        }
                    }).catch((error) => {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error del servidor',
                            text: 'Inténtalo de nuevo en unos momentos.',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    })

                } else {
                    try{
                        _response.email = user.email
                        handleLogin(_response)
                    } catch (err){
        
                    }

                }
            }).catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error del servidor',
                    text: 'Inténtalo de nuevo en unos momentos.',
                    showConfirmButton: false,
                    timer: 1500
                })
            })

        })
    }

    const loginFacebook = () => {
        auth().signInWithPopup(providerFB)
          .then(({ user }) => {

            Swal.showLoading();

            values.append("uid",user.uid);
            values.append("tipoLogin", "facebook");
            values.append("fotoPerfilRedes", user.photoURL);

            let objectValues = Object.fromEntries(values);

            loginSocialMedia({values:objectValues}).then((response) => {
                let _response = JSON.parse(response);
                if(_response.response === "error"){

                    values.append("nombreUsuario", user.displayName);
                    values.append("correo", user.email);
                    values.append("especialidad", "sin especialidad");
                    values.append("medioRegistro", "web")
                    values.append("fotoPerfilRedes", user.photoURL);

                    objectValues = Object.fromEntries(values);

                    SignUpSocialMedia({values:objectValues}).then((response) => {
                        let _response = JSON.parse(response);
                        if(_response.response === "error"){
        
                            Swal.fire({
                                icon: 'error',
                                title: 'La cuenta ha sido desactivada o hubo un error',
                                text: 'Inténtalo de nuevo, si el problema persiste contáctanos',
                                showConfirmButton: false,
                                timer: 1500
                            })
        
                        } else {
                            try{
                                _response.email = user.email
                                handleLogin(_response)
                            } catch (err){
                
                            }
        
                        }
                    }).catch((error) => {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error del servidor',
                            text: 'Inténtalo de nuevo en unos momentos.',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    })

                } else {
                    try{
                        _response.email = user.email
                        handleLogin(_response)
                    } catch (err){
        
                    }

                }
            }).catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error del servidor',
                    text: 'Inténtalo de nuevo en unos momentos.',
                    showConfirmButton: false,
                    timer: 1500
                })
            })

        })
    }

    const loginByEmail = (e) => {

        e.preventDefault();

        Swal.showLoading();

        let values = new FormData();

        let correo = $("#correoInputCheckout").val();
        let pass = $("#contrasenaInputCheckout").val();

        values.append("correo", correo);
        values.append("password", pass);

        let objectValues = Object.fromEntries(values);

        if(correo.trim().length > 0 && pass.trim().length > 0){
            loginEmail({values:objectValues}).then((response) => {
                let _response = JSON.parse(response);
                if(_response.response === "error"){
                    Swal.fire(
                        'Ocurrió un error',
                        'Revisar tus datos de nuevo.',
                        'error'
                    )
                } else {
                    try{
                        _response.email = correo
                        handleLogin(_response)
                    } catch (err){
        
                    }
                }
            }).catch((error) => {
                Swal.fire(
                    'Error del servidor',
                    'Inténtalo de nuevo en unos momentos.',
                    'error'
                )
            })
        }else{
            Swal.fire(
                'Ocurrió un error',
                'Verifica tus datos',
                'error'
            )
        }
    }


    const registerByEmail = (e) => {

        e.preventDefault();

        Swal.showLoading();

        let values = new FormData();

        let nombre = $("#nombreInputCheckout").val();
        let especialidad = $("#especialidadInputCheckout").val();
        let correo = $("#correoInputCheckoutRegistro").val();
        let pass = $("#contrasenaInputCheckoutRegistro").val();
        let numero = $("#numeroInputCheckout").val();

        if(correo.trim().length > 0 && pass.trim().length > 0 && nombre.trim().length > 0 && especialidad.trim().length > 0) {

            values.append("nombre", nombre);
            values.append("correo", correo);
            values.append("numero", numero);
            values.append("especialidad", especialidad);
            values.append("password", pass);

            let objectValues = Object.fromEntries(values);

            registroCheckout({values:objectValues}).then((response) => {
                let _response = JSON.parse(response);
                if(_response.response === "error"){
                    
                    try{
                        localStorage.setItem('correoCheckout', correo);
                        siguientePaso();
                        Swal.close();
                    } catch (err){
        
                    }

                } else {
                    try{
                        _response.email = correo
                        handleLogin(_response)
                    } catch (err){
        
                    }
                }
            }).catch((error) => {
                Swal.fire(
                    'Error del servidor',
                    'Inténtalo de nuevo en unos momentos.',
                    'error'
                )
            })
        }else{
            Swal.fire(
                'Ocurrió un error',
                'Verifica tus datos',
                'error'
            )
        }
    }

    const handleLogin = (data) => {
        localStorage.setItem('idUsuario', data.idUsuario);
        localStorage.setItem('tipoUsuario', data.tipoUsuario);
        localStorage.setItem('nombre', data.nombre);
        localStorage.setItem('statusMembresia', data.statusMembresia);
        localStorage.setItem('correoUser', data.email);
        localStorage.setItem('correoCheckout', data.email);
        setLogin(true)
        Swal.close()
    }

    function registroCorreo() {
        $("#checkout__formularioRegistro").removeClass("hidden");
        $("#checkout__login").addClass("hidden");
    }

    function atras() {
        $("#checkout__formularioRegistro").addClass("hidden");
        $("#checkout__login").removeClass("hidden");
    }

    return (
        <div id="crearCuenta-part" role="tabpanel" aria-labelledby="crearCuenta-part-trigger" animation="true">

            <div className="border rounded p-4 my-5 text-center" id="checkout__login">
                <span className="mb-2"><b>Regístrate para continuar:</b></span>
                <div onClick={loginGoogle} className="botonCheckoutGoogle btn btn-light w-100 sombraBotonesCheckout">
                    <i className="fab fa-google iconoBottonCheckout"></i>Continuar con Google
                </div>
                <div onClick={loginFacebook} className="botonCheckoutFacebook btn w-100 sombraBotonesCheckout">
                    <i className="fab fa-facebook-f iconoBottonCheckout"></i>Continuar con Facebook
                </div>
                <div onClick={registroCorreo} className="botonCheckoutCorreo btn w-100 sombraBotonesCheckout">
                    <i className="fas fa-envelope iconoBottonCheckout"></i>Continuar con mi correo
                </div>

                <hr className="hrCheckout mb-4"/>

                <span className="mb-2"><b>Si ya tienes una cuenta, inicia sesión:</b></span>
                
                <form id="formIniciarSesionCheckout" className="mt-4" onSubmit={loginByEmail}>
                    <div className="form-group">
                        <input type="email" className="form-control" id="correoInputCheckout" aria-describedby="correo" placeholder="Correo electrónico" required/>
                    </div>
                    <div className="form-group mt-3 mb-4">
                        <input type="password" className="form-control" id="contrasenaInputCheckout" aria-describedby="emailHelp" placeholder="Contraseña" required/>
                    </div>
                    <button id="iniciarSesionCheckout" type="submit" className="btn btn-light w-100 btn-block">INICIAR SESIÓN</button>
                </form>
            </div>

            <div className="border rounded p-4 my-5 hidden" id="checkout__formularioRegistro">
                <div><b onClick={atras} className="btnAtrasRegistro"><i className="fas fa-chevron-left"></i> Volver</b></div>
                <div className="mb-2 text-center"><b>Para continuar debes crear una cuenta</b></div>
                <form id="formRegistroCheckout" className="mt-4" onSubmit={registerByEmail}>
                    <div className="form-group mb-4">
                        <input type="text" className="form-control" id="nombreInputCheckout" aria-describedby="nombre" placeholder="Nombre y apellidos"/>
                    </div>
                    <div className="form-group mb-4">
                        <input type="text" className="form-control" id="especialidadInputCheckout" aria-describedby="Especialidad" placeholder="Especialidad (dentista, técnico dental, estudiante)"/>
                    </div>
                    <div className="form-group mb-4">
                        <input type="email" className="form-control" id="correoInputCheckoutRegistro" aria-describedby="Correo electrónico" placeholder="Correo electrónico"/>
                    </div>
                    <div className="form-group mb-4">
                        <input type="tel" className="form-control" id="numeroInputCheckout" aria-describedby="Número de celular" placeholder="Número de celular"/>
                    </div>
                    <div className="form-group">
                        <input type="password" className="form-control" id="contrasenaInputCheckoutRegistro" aria-describedby="Contraseña" placeholder="Contraseña"/>
                    </div>
                    <div className="form-check text-left my-5">
                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                        <label className="form-check-label" htmlFor="flexCheckDefault">
                            <span>Acepto los <span className="txtTerminos">Términos, Condiciones y Políticas de Cursodontic.</span></span>
                        </label>
                    </div>
                    <button id="registroCheckout" type="submit" className="btn btn-light w-100 btn-block">REGISTRARME Y CONTINUAR</button>
                </form> 
            </div>

            <div className="text-center mb-5">
                <span className="note"><b>Estás a sólo dos pasos de adquirir tu curso</b></span>
            </div>
            
        </div>
    );
}

export {CrearCuenta};