import React, { useEffect, useState } from 'react';
import '../assets/css/InfoCurso.css';

import {CardCursoCompra} from '../components/CardCursoCompra/CardCursoCompra';
import {Calificacion} from '../components/Calificacion/Calificacion';
import {Ponente} from '../components/Ponente/Ponente';
import {DetallesCurso} from '../components/DetallesCurso/DetallesCurso';
import {Descripcion} from '../components/Descripcion/Descripcion';
import {PlanEstudios} from '../components/PlanEstudios/PlanEstudios';
import {Entrevista} from '../components/Entrevista/Entrevista';
import {Resenas} from '../components/Resenas/Resenas';
import {Materiales} from '../components/Materiales/Materiales';
import {Cursos} from '../components/Cursos/Cursos';
import {Membresia} from '../components/Membresia/Membresia';
import {Denthereum} from '../components/Denthereum/Denthereum';

import { Redirect } from 'react-router-dom';

import config from '../config';
// import Swal from 'sweetalert2';
import getCourses from '../services/getCourses';

function InfoCurso({login, setLogin, setModalLogin}) {

    let idUsuario = localStorage.getItem('idUsuario');

    let nombreCursoUrl = (window.location.href).split('/');

    // console.log(urlCurso+nombreCurso[4]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
        getCourse(nombreCursoUrl);
    }, [window.location.href, login]);

    const usuarioMembresia = localStorage.getItem('statusMembresia')

    const [cursoComprado, setCursosComprados] = useState([]);

    const [nombreCurso, setNombreCurso] = useState([]);

    const [descripcionCurso, setDesripcionCurso] = useState([]);
    
    const [imagenCurso, setImagenCurso] = useState('https://cursodontic.com/asset/img/imagenesCurso/loading-image-course.png');
    
    const [precio, setPrecio] = useState([]);

    const [divisa, setDivisa] = useState([]);
    
    const [descuento, setDescuento] = useState([]);

    const [nombrePonente, setNombrePonente] = useState([]);
    
    const [urlImagenPonente, setUrlImagenPonente] = useState('imgPerfilPredeterminado.jpg');
    
    const [numeroClases, setNumeroClases] = useState([]);
    
    const [visitas, setVisitas] = useState([]);
    
    const [totalMinutos, setTotalMinutos] = useState([]);
    
    const [promedioCurso, setPromedioCurso] = useState([]);
    
    const [numOpiniones, setNumOpiniones] = useState([]);
    
    const [totalAlumnos, setTotalAlumnos] = useState([]);
    
    const [idYoutube, setIdYoutube] = useState([]);
    
    const [clases, setClases] = useState([]);
    
    const [resenas, setResenas] = useState([]);
    
    const [idCurso, setIdCurso] = useState([]);
    
    const [cursos, setCategoria] = useState([]);

    const [materiales, setMateriales] = useState([]);
    
    const [proyectoVidello, setProyectoVidello] = useState([]);
    
    function getCourse(nombreCursoUrl) {
        setLoading(true);
        let formData = new FormData();

        formData.append('nombreCurso', nombreCursoUrl[4]);
        if(idUsuario !== null)
            formData.append('idUsuario', idUsuario);

        let url = config.urlApi+'controladores/infoCurso.app.php';

        getCourses(formData,url).then(response => {
            console.log(response)
            let _respuesta = JSON.parse(response);
            console.log(_respuesta);

            if(_respuesta.response === 'success'){
                setIdCurso(_respuesta.datos.id_curso);
                setCategoria(_respuesta.datos.cursosRelacionados);
                setNombreCurso(_respuesta.datos.tituloCurso);
                setCursosComprados(_respuesta.datos.cursosComprados);
                setDesripcionCurso(_respuesta.datos.descripcionCurso);
                setPrecio(_respuesta.datos.precio);
                setDivisa(_respuesta.datos.divisa);
                setImagenCurso(_respuesta.datos.imagenCurso);
                setDescuento(_respuesta.datos.descuento);
                setNombrePonente(_respuesta.datos.detallesGenerales.nombrePonente);
                setUrlImagenPonente(_respuesta.datos.detallesGenerales.imagenPonente);
                setNumeroClases(_respuesta.datos.detallesGenerales.totalClases);
                setVisitas(_respuesta.datos.visitas);
                setTotalMinutos(_respuesta.datos.detallesGenerales.duracionTotalCurso);
                setPromedioCurso(_respuesta.datos.detallesGenerales.promedioCurso);
                setNumOpiniones(_respuesta.datos.detallesGenerales.numOpiniones);
                setTotalAlumnos(_respuesta.datos.detallesGenerales.totalAlumnos);
                setClases(_respuesta.datos.planEstudiosCurso);
                setMateriales(_respuesta.datos.materialesCurso);
                setProyectoVidello(_respuesta.datos.proyectoVidello);
                if(!(_respuesta.datos.urlVideoPromocional)) {
                    setIdYoutube('');
                } else {
                    setIdYoutube((_respuesta.datos.urlVideoPromocional).split('/')[4]);
                }

                if(!(_respuesta.datos.comentariosCurso)) {
                    setResenas('');
                } else {
                    setResenas(_respuesta.datos.comentariosCurso);
                }

                let titulo = document.getElementById("tituloWeb");
                titulo.innerHTML = 'Curso | '+_respuesta.datos.tituloCurso;

                setLoading(false);

            }

        })
        .catch(error => {
            console.log(error);
        })
    }
    // mis-cursos/protectores-bucales-personalizados/1
    // function validarBtnCompra(cursoComprado) {
    //     return (!cursoComprado): 
    // }
    
    function validateInterviewLx(idYoutube) {

        if(idYoutube === '') {

            return (
                <div className="row my-4">

                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <Descripcion descripcionCurso={descripcionCurso}/>
                    </div>

                </div>
            )

        } else {
            
            return(
                <div className="row my-4">

                    <div className="col-0 col-sm-0 col-md-0 col-lg-6 col-xl-6">
                        <Entrevista nombrePonente={nombrePonente} idYoutube={idYoutube}/>
                    </div>

                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <Descripcion descripcionCurso={descripcionCurso}/>
                    </div>

                </div>
            )

        }

    }

    function validateInterviewSm(idYoutube) {

        if(!(idYoutube === '')) {
            return(
                <div className="col-12 col-sm-12 col-md-12 col-lg-0 col-xl-0">
                    <Entrevista nombrePonente={nombrePonente} idYoutube={idYoutube}/>
                </div>
            )
        }
        
    }

    function showMembership(usuarioMembresia) {

        if(usuarioMembresia !== '1') {
            return (
                <div className="my-5">
                    <Membresia login={login} setModalLogin={setModalLogin} />
                </div>
            )
        }
    }

    return(!nombreCurso ? <Redirect to="/" /> 
        :
        (loading) ? <div className="text-center mt-5">
                        <div className="lds-ellipsis">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                    :
        <div className="infoCurso">

            <div className="infoCurso-gradient">

                <div className="container">

                    <div className="row">

                        <div className="col-12 col-sm-12 col-md-6 col-lg-7 col-xl-6 mb-4">

                            <p className="bebas mt-4 titulos text-white">
                                { nombreCurso }
                            </p>
                            
                            {/* <p className="infoCurso-subtitulo">
                                { descripcionCurso }
                            </p> */}

                            <div className="d-flex mt-4 col-sm-0 col-0">
                                <div className="bebas">
                                    <span className="px-2 py-1 infoCurso-chip">+1 DENTHEREUM</span>
                                    {/* <span className="px-2 py-1 infoCurso-chip mx-3">+ CERTIFICADO</span> */}
                                </div>
                            </div>

                            <div className="col-sm-0 col-0">
                                <Ponente nombrePonente={nombrePonente} urlImagenPonente={urlImagenPonente}/>
                            </div>

                            <div className="col-0 col-sm-0">
                                <DetallesCurso numeroClases={numeroClases} totalMinutos={totalMinutos} visitas={visitas}/>
                            </div>

                            <div className="col-sm-0 col-0">
                                <Calificacion promedioCurso={promedioCurso} numOpiniones={numOpiniones} totalAlumnos={totalAlumnos}/>
                            </div>

                        </div>

                        <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-6">

                            <CardCursoCompra 
                                imagenCurso={imagenCurso} 
                                precio={precio} 
                                descuento={descuento} 
                                divisa={divisa} 
                                cursoComprado={cursoComprado} 
                                nombreCursoUrl={nombreCursoUrl[4]} 
                                idCurso={idCurso} 
                                nombreCurso={nombreCurso} 
                                proyectoVidello={proyectoVidello}
                                setLogin={setLogin}
                                setModalLogin={setModalLogin}
                            />

                        </div>

                        <div className="col-12 col-sm-12 col-md-0 col-lg-0 col-xl-0 py-4">
                            <Ponente nombrePonente={nombrePonente} urlImagenPonente={urlImagenPonente}/>
                            <Calificacion promedioCurso={promedioCurso} numOpiniones={numOpiniones} totalAlumnos={totalAlumnos}/>
                        </div>

                    </div>

                </div>

            </div>

            <div className="col-md-0 col-lg-0 col-xl-0"><DetallesCurso numeroClases={numeroClases} totalMinutos={totalMinutos} visitas={visitas}/></div>

            <div className="bg-white pb-3">

                <div className="container">

                    {/* <div className="row my-4">

                        <div className="col-0 col-sm-0 col-md-0 col-lg-6 col-xl-6">
                            <Entrevista nombrePonente={nombrePonente} idYoutube={idYoutube}/>
                        </div>

                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <Descripcion />
                        </div>

                    </div> */}

                    {validateInterviewLx(idYoutube)}
                    
                    <PlanEstudios clases={clases}/>

                    {/* <div className="col-12 col-sm-12 col-md-12 col-lg-0 col-xl-0">
                        <Entrevista nombrePonente={nombrePonente} idYoutube={idYoutube}/>
                    </div> */}

                    {validateInterviewSm(idYoutube)}
                    
                    <Resenas texto="CURSO" resenas={resenas}/>
                    
                    <Materiales mats={materiales} isDescripcion={false}/>

                    <Cursos cursos={cursos} titulo={'CURSOS SIMILARES'} darkMode={false}/>
                    
                    {showMembership(usuarioMembresia)}

                    <Denthereum />

                </div>
                
            </div>

        </div>
    );
}

export {InfoCurso};