import React from 'react';
import { Link } from 'react-router-dom';
import './ResultadosItem.css';
import Logo from '../../assets/images/LogoDenthereumAmarillo.png';

function ResultadosItem(props){

    return (

        <Link to={(props.type === 'material') ? '/materiales/acetatos/'+props.urlPage :'/cursos/'+ props.urlPage} className="" >

            <div className={`${props.darkMode ? 'cardBusqueda__dark' : 'cardBusqueda'}`}>

                <div className={`cardBusqueda__header ${props.darkMode}`}>

                    <img src={props.url} alt="" className="cardBusqueda__imagen"></img>
                    <i className={`fa-solid fa-circle-play cardBusqueda__play ${(props.type === 'material') && 'd-none'} `}></i>

                </div>

                <div className="cardBusqueda__body">
                    <div className="cardBusqueda__detalles">

                        <div className={`flag ${props.clase}`}>
                            
                            <span className="flag__texto bebas">{props.tipo}</span>
                    
                        </div>

                        <p className="cardBusqueda__nombreItem d-flex align-items-center">{props.nombre}</p>
                    </div>    

                    <hr className="cardBusqueda__separador"></hr>

                    <div className="cardBusqueda__precios">

                        <p className="cardBusqueda__txtPrecio">Precio:</p>

                        <div className="cardBusqueda__cantidades">

                            {
                                (parseFloat(props.fiat)) === 0 &&
                                <span className='text-rojo font-weight-bold'>
                                    GRATIS
                                </span>
                            }
                            {
                                (parseFloat(props.fiat)) !== 0 &&
                                <p className="cardBusqueda__cantidadFiat font-weight-bold">
                                    $ {parseFloat(props.fiat).toFixed(0)}&nbsp;
                                    <small>
                                        {props.divisa}
                                    </small>
                                </p>
                            }

                        </div>

                    </div>
                    
                </div>

            </div>

        </Link>
        
    );
}

export {ResultadosItem};