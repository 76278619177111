import { TodayTwoTone } from '@material-ui/icons';
import React from 'react';

import './ResenaItem.css';

function ResenaItem(props) {

    function stars(calificacion,fecha) {

        let finalDate = new Date(fecha);

        switch (calificacion){

            case '1':
                return (
                    <div className="infoCursoResenaItem-rate">
                        <i className="fa fa-star"></i>
                        <span className="infoCursoResenaItem-tiempo mx-2">{`Comentado el ${finalDate.toLocaleDateString(undefined, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}`}</span>
                    </div>
                )
            break;

            case '2':
                return (
                    <div className="infoCursoResenaItem-rate">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star mx-1"></i>
                        <span className="infoCursoResenaItem-tiempo mx-2">{`Comentado el ${finalDate.toLocaleDateString(undefined, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}`}</span>
                    </div>
                    )
            break;

            case '3':
                return (
                    <div className="infoCursoResenaItem-rate">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star mx-1"></i>
                        <i className="fa fa-star"></i>
                        <span className="infoCursoResenaItem-tiempo mx-2">{`Comentado el ${finalDate.toLocaleDateString(undefined, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}`}</span>
                    </div>
                    )
            break;

            case '4':
                return (
                    <div className="infoCursoResenaItem-rate">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star mx-1"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star mx-1"></i>
                        <span className="infoCursoResenaItem-tiempo mx-2">{`Comentado el ${finalDate.toLocaleDateString(undefined, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}`}</span>
                    </div>
                    )
            break;

            case '5':
                return (
                    <div className="infoCursoResenaItem-rate">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star mx-1"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star mx-1"></i>
                        <i className="fa fa-star"></i>
                        <span className="infoCursoResenaItem-tiempo mx-2">{`Comentado el ${finalDate.toLocaleDateString(undefined, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}`}</span>
                    </div>
                    )
            break;

        }
    }

    return(
        <div className="infoCursoResenaItem my-3 p-3" key={props.idKey}>
            <div className="d-flex align-items-center">
                <img className="infoCursoResenaItem-imagen" src={`https://cursodontic.com/asset/img/fotosPerfil/${props.fotoPerfil}`} alt="usuario" />
                <div className="mx-3">
                    <b className="infoCursoResenaItem-nombre">{props.nombre}</b>
                    {stars(props.calificacion, props.fecha)}
                </div>
            </div>
            <div className="my-3">
                <span className="infoCursoResenaItem-nombre">{props.comentario}</span>
            </div>
        </div>
    );
}

export {ResenaItem};