import React, { useState, useEffect } from 'react';

import '../assets/css/InfoMaterial.css';
import '../components/ComprarAhora/ComprarAhora.css';

import {CarruselImgMaterial} from '../components/CarruselImgMaterial/CarruselImgMaterial.js';
import {CalificacionMaterial} from '../components/CalificacionMaterial/CalificacionMaterial.js';
import {PrecioDenth} from '../components/PrecioDenth/PrecioDenth.js';
import{Resenas} from '../components/Resenas/Resenas.js';
import {Materiales} from '../components/Materiales/Materiales.js';
import {Cursos} from '../components/Cursos/Cursos.js';
import {Denthereum} from '../components/Denthereum/Denthereum.js';
import {ComprarAhora} from '../components/ComprarAhora/ComprarAhora.js';
import {InputCantidad} from '../components/InputCantidad/InputCantidad.js';
import {DescripcionMaterial} from '../components/DescripcionMaterial/DescripcionMaterial.js';

import config from '../config';
// import Swal from 'sweetalert2';
import getCourses from '../services/getCourses';

import ReactLoading from 'react-loading';

function InfoMaterial(props){

    let idUsuario = localStorage.getItem('idUsuario');

    let urlNombreMaterial = (window.location.href).split('/');
    // console.log(window.location.href);

    const [nombreMaterial, setNombreMaterial] = useState([]);

    const [imagenesMaterial, setImagenesMaterial] = useState([{ id_imagenProducto: "46", ruta: "https://cursodontic.com/asset/img/tiendas/proplay/PROTECTORES_MIXTO.jpg", id_producto: "28" }]);

    const [calificacion, setCalificacion] = useState([]);

    const [fechaEntrega, setFechaEntrega] = useState([]);

    const [precioProducto, setPrecioProducto] = useState([]);

    const [pais, setPais] = useState([]);

    const [descripcionProducto, setDescripcionProducto] = useState([]);

    const [resenas, setResenas] = useState([]);

    const [isMixto, setMixto] = useState([]);
    const [producto, setProducto] = useState([]);
    const [color, setColor] = useState([]);

    const [cursosPopulares, setCursosPopulares ] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
        getInfoMaterial(urlNombreMaterial[5]);
        getPopularCourses();
    }, [window.location.href, props.login]);

    function getPopularCourses() {
        let formData = new FormData();

        formData.append('idUsuario', idUsuario);

        let url = config.urlApi+'controladores/cursosPopulares.app.php';

        getCourses(formData,url).then(response => {
            let _respuesta = JSON.parse(response);
            if(_respuesta.response === 'success'){
                setCursosPopulares(_respuesta.datos);
            }

        })
        .catch(error => {
            console.log(error);
        })
    }

    function getInfoMaterial(urlNombreMaterial) {

        let formData = new FormData();

        formData.append('nombreProducto', urlNombreMaterial);

        let url = config.urlApi+'controladores/infoProducto.app.php';

        getCourses(formData,url).then(response => {
            let _respuesta = JSON.parse(response);
            console.log(_respuesta);
            if(_respuesta.response === 'success') {
                if(_respuesta.datos.esMixto) {
                    setImagenesMaterial(_respuesta.datos.imagenesgeneral);
                } else {
                    setImagenesMaterial(_respuesta.datos.imagenes);
                }
                setNombreMaterial(_respuesta.datos.nombreFinalProducto);
                setCalificacion(_respuesta.datos.calificacion);
                setFechaEntrega(_respuesta.datos.fechaEntrega);
                setMixto(_respuesta.datos.esMixto);
                setProducto(_respuesta.datos.id_producto);
                setColor(_respuesta.datos.idColor);
                setPrecioProducto(_respuesta.datos.precioProducto);
                setPais(_respuesta.datos.pais);
                setDescripcionProducto(_respuesta.datos.descripcionProducto);

                if(!(_respuesta.datos.calificacion['datosReviews'])) {
                    setResenas('');
                } else {
                    setResenas(_respuesta.datos.calificacion['datosReviews']);
                }

                let titulo = document.getElementById("tituloWeb");
                titulo.innerHTML = 'Material | '+_respuesta.datos.nombreFinalProducto;
                
            }
        })
        .catch(error => {
            console.log(error);
        })
    }
    
    return(
        <div className="infoMaterial">

            {resenas === "" || resenas.length > 0 ?

                <>
                    <div className="infoMaterial-gradient">

                        <div className="row">

                            <div className="col-12 col-sm-12 col-md-7 col-lg-6 col-xl-6">

                                <CarruselImgMaterial imagenesMaterial={imagenesMaterial} />

                            </div>

                            <div className="col-12 col-sm-12 col-md-5 col-lg-6 col-xl-6">

                                <p className="bebas mt-2 titulos text-white">PAQUETE DE ACETATOS 20 PIEZAS {nombreMaterial}</p>

                                <CalificacionMaterial calificacion={calificacion} fechaEntrega={fechaEntrega} />
                                <PrecioDenth precioProducto={precioProducto} pais={pais} />
                                <InputCantidad />
                                <ComprarAhora
                                    clase={'text-white'}
                                    isMixto={isMixto}
                                    producto={producto}
                                    color={color}
                                    isCurso={false}
                                    nombreArticulo={nombreMaterial}
                                    imagen={imagenesMaterial[0].ruta}
                                    isProduct={true}
                                    setModalLogin={props.setModalLogin}
                                />

                            </div>

                        </div>

                    </div>

                    <div className="bg-white">

                        <div className="container">

                            <DescripcionMaterial descripcionProducto={descripcionProducto} />

                            <Resenas texto="MATERIAL" resenas={resenas} />

                            {/* OMAR LE TIENES QUE PASAR EL ARREGLO DE LOS MATERIALES, LE PUSE UN ARREGLO VACÍO POR QUE SI NO PETABA */}
                            {/* <Materiales mats={[]} /> */}
                            <Cursos cursos={cursosPopulares} titulo={'CURSOS RECOMENDADOS'} darkMode={false} />
                            <Denthereum />

                        </div>

                    </div>
                </>

                :

                <div className="circularBarProgressIndicator">
                    <ReactLoading type="spin" color="#F65F5D" height={'50px'} width={'50px'} className="circularBarProgressIndicatorItem" />
                </div>
            
            }

            

        </div>
    );
}

export {InfoMaterial};