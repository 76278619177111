import React, { useState, useEffect } from 'react';
import { Box, Grid } from '@mui/material';

import RecentlyPlayed from '../components/recentlyPlayedCard/RecentlyPlayed';
import DentherumEarned from '../components/dentherumEarned/DentherumEarned';
import WeeklyProgress from '../components/weeklyProgress/WeeklyProgress';
import UserCertificates from '../components/userCertificates/UserCertificates';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../components/dashboard/dashboard.css';

const Dashboard = (props) => {

    let nombre = localStorage.getItem('nombre')

    useEffect(() => {
        let titulo = document.getElementById("tituloWeb");
        titulo.innerHTML = 'Cursodontic | Dashboard';
        window.scrollTo(0, 0);
    } , []);

    return (
        <div className="container my-5 py-2">

            <div className="col-12">
                <h1 className="font-bebas dashboard-name">{`Bienvenido, ${nombre ? nombre.toUpperCase() : 'Anónimo'}`}</h1>
            </div>

            <Grid item xs={12} mt={3} className="d-flex justify-content-center align-items-center">
                <RecentlyPlayed />
            </Grid>

            <hr className='mb-4'/>

            <div className="row">

                <div className="d-flex col-sm-12 col-md-6 col-lg-6 col-xl-6 weekly-progress-container">
                    {/* <WeeklyProgress/> */}
                    <DentherumEarned 
                        handleLogin={props.handleLogin}
                        saldoTokens={props.saldoTokens}
                        ingresoTokens={props.ingresoTokens}
                    />
                </div>
                <div className="d-flex col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-sm mt-xs">
                    <UserCertificates />
                </div>
            </div>
        </div>
    )
}

export default Dashboard;