/* global BigInt */
import React, { useMemo } from 'react';

import './PagoCarrito.css';

import $ from 'jquery';

import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe, useElements, CardElement } from "@stripe/react-stripe-js";

import createPaymentIntentCarrito from "../../services/createPaymentIntentCarrito.js";

import paymentCompleteCarrito from "../../services/paymentCompleteCarrito.js";

import getCart from "../../services/getCart.js";

import Swal from 'sweetalert2';

import { useHistory } from 'react-router';
import { sendPurchase } from '../../utils/facebookPixelEvents';

import { Link } from 'react-router-dom';
import iconoLogo from './../../assets/images/iconodtm2.png';
import ReactLoading from 'react-loading';
import { ethers } from 'ethers';
import DenthereumArtifact from './../../web3/contracts/artifacts/Denthereum';
import { useWeb3React } from '@web3-react/core';
import ButtonConnectWallet from './../ButtonConnectWallet';
const { abi, address } = DenthereumArtifact;

const stripePromise = loadStripe(process.env.REACT_APP_KEY_STRIPE);

const PagoCarrito = (props) => {

    const stripe = useStripe();
    const elements = useElements();
    const history = useHistory()

    const[proceso, setProceso] = React.useState([]);
    const[costoTotal, setTotal] = React.useState([]);
    const[costoTotalUSD, setTotalUSD] = React.useState(0);

    let idCurso = props.curso;
    let usuario = props.idUsuario;
    let urlCortaCurso = props.urlCurso;
    let direccion = props.direccion;
    let tipoPago = "card";

    let urlVoucher;

    let objectValues;
    let objectValores;

    let valores;

    const [emailUser, setCorreoUser] = React.useState(false);
    const [emailCeckout, setCorreoCheckout] = React.useState(false);

    const [loadingPayment, setLoadingPayment] = React.useState(false)
    const [blockHash, setBlockHash] = React.useState('')
    const [mensajeTransaccionDenthereum, setMensajeTransaccionDenthereum] = React.useState();
    const { active, account } = useWeb3React()
    const [precioDenthereum, setPrecioDenthereum] = React.useState(0)

    React.useEffect(() => {

        window.scrollTo(0, 0);

        try{
            const datoCorreoUser = localStorage.getItem('correoUser');
            const datoCorreoCheckout = localStorage.getItem('correoCheckout');

            if(datoCorreoUser === 'null' && datoCorreoCheckout === undefined) {

            }else{
                setCorreoUser(datoCorreoUser);
            }

            if(datoCorreoCheckout === 'null' && datoCorreoCheckout === undefined){

            }else{
                setCorreoCheckout(datoCorreoCheckout);
            }

        } catch (err){

        }

        setProceso(props.proceso);

        let values = new FormData();

        values.append("idUsuario", usuario);

        objectValores = Object.fromEntries(values);

        getCartData()

        console.log(props.saldoTokens, precioDenthereum)

    }, []);

    function getCartData() {

        getCart({ values: objectValores }).then((response) => {
            let _response = JSON.parse(response);
            console.log(_response);
            if (_response.response === "error") {
                setTotal(_response.total);
            } else {
                // Seteamos el precio de denthereum por si paga por ahi
                // No se otorga el 10% de descuento por pagar por ese medio
                setPrecioDenthereum(parseFloat(_response.totalUSD)*0.95)
                setTotalUSD(_response.totalUSD)
                setTotal(_response.total);
            }
        }).catch((error) => {
            Swal.fire(
                'Error del servidor',
                'Inténtalo de nuevo en unos momentos.',
                'error'
            )
        })
    }

    const handleSubmit = async (e) =>  {
        
        e.preventDefault();

        Swal.showLoading();

        let nombreTitular = $("#nombreTarjetaCompra").val();
    
        if (!stripe || !elements) {
          return;
        }

        let values = new FormData();

        let correoEnviar;

        if(emailUser !== false){
            correoEnviar = emailUser;
        }

        if(emailCeckout !== false){
            correoEnviar = emailCeckout;
        }
        
        values.append("idUsuario",usuario);
        values.append('pago', tipoPago);
    
        objectValues = Object.fromEntries(values);

        console.log(objectValues);

        createPaymentIntentCarrito({values:objectValues}).then( async (response) => {
            console.log(response)
            let _response = JSON.parse(response);
            if(_response.response === "error"){
                    Swal.fire({
                        icon: 'error',
                        title: 'Ocurrió un error',
                        text: 'Revisar la conexión a internet e inténtalo de nuevo.',
                        showConfirmButton: true,
                        timer: 1500
                    })
            } else {
                console.log('CREATE PAYMENT INTENT FUNCTION');
                console.log(_response);
                let clientSecret = _response.cliente;
                let correo = _response.correo;
                let nombre = _response.nombre;

                if(tipoPago === "card"){

                    const {error: stripeError, paymentIntent} = await stripe.confirmCardPayment(
                        clientSecret, {
                            payment_method: {
                                card: elements.getElement(CardElement),
                                billing_details: {
                                    name: nombreTitular,
                                    email: correo
                                }
                            }
                        }
                    );

                    if(stripeError){
                        Swal.fire({
                            icon: 'error',
                            text: stripeError.message,
                            showConfirmButton: true
                        })
                    }else{

                        if(paymentIntent.status === "succeeded"){

                            let paymentIntentId = paymentIntent.id;
                            procesoCompletado(true,"card",correo,paymentIntentId, idCurso);

                        }

                    }

                }else if (tipoPago === "oxxo"){
                    let correoEnvioTicketOxxo = $("#correoEnvioTicketOxxo").val();
                    let nombreDepositoOxxo = $("#nombreDepositoOxxo").val();
                    const {error: stripeError, paymentIntent} = await stripe.confirmOxxoPayment(
                        clientSecret, {
                            payment_method: {
                                billing_details: {
                                    name: nombreDepositoOxxo,
                                    email: correoEnvioTicketOxxo
                                }
                            }
                        }
                    );

                    if(stripeError){
                        Swal.fire({
                            icon: 'error',
                            text: stripeError.message,
                            showConfirmButton: true
                        })
                    }else{

                        Swal.close();
                        let paymentIntentId = paymentIntent.id;
                        console.log(paymentIntentId);
                        procesoCompletado(true,"oxxo",correo,paymentIntentId, idCurso);

                    }

                }
            }

        }).catch((error) => {
            Swal.fire({
                icon: 'error',
                title: 'Error del servidor',
                text: 'Inténtalo de nuevo en unos momentos.',
                showConfirmButton: false,
                timer: 1500
            })
        })
    };

    var procesoCompletado = function(complete, tipoPago, email, paymentIntentId, idCurso){
    
        let datos = new FormData();
        
        datos.append("idUsuario",usuario);
        datos.append('tipoPago', tipoPago);
        datos.append('paymentIntentId', paymentIntentId);
        datos.append('direccion', direccion);
    
        valores = Object.fromEntries(datos);

        console.log(valores);

        Swal.showLoading();

        paymentCompleteCarrito({values:valores}).then((response) => {

            console.log(response);
            
            let _response = JSON.parse(response);

            if(_response.response === "success"){

                if(tipoPago === "oxxo"){

                    try{
                        localStorage.removeItem("correoCheckout");
                        // localStorage.setItem('idUsuario', _response.idUsuario);
                        localStorage.setItem('tipoUsuario', _response.tipoUsuario);
                        localStorage.setItem('nombre', _response.nombre);
                        localStorage.setItem('statusMembresia', _response.statusMembresia);
                        
                    } catch (err){
        
                    }

                    props.setFinalized(true);
                    
                    urlVoucher = _response.urlVoucher;
                    setProceso("ticket");
                    $("#verTicketCheckout").attr("href",urlVoucher);
                    window.scrollTo(0, 0);
    
                } else if(tipoPago === 'card' || tipoPago === 'denthereum') {
    
                    try{
    
                        localStorage.removeItem("correoCheckout");
                        // localStorage.setItem('idUsuario', _response.idUsuario);
                        localStorage.setItem('tipoUsuario', _response.tipoUsuario);
                        localStorage.setItem('nombre', _response.nombre);
                        localStorage.setItem('statusMembresia', _response.statusMembresia);
                        
                    } catch (err){
        
                    }
                    
                    props.setFinalized(true);
                    setProceso("realizado");
                    Swal.fire({
                        icon: 'success',
                        title: 'Pago exitoso',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    window.scrollTo(0, 0);
                    sendPurchase(props.divisa, props.total, props.idCurso, props.urlCurso)
    
                }

            }

        }).catch((error) => {
        })
        
    }

    function verCurso(){
        history.push("mis-cursos");
    }

    function cardPay() {
        $("#pagoTarjeta").removeClass("hidden");
        $("#pagoDepositoOxxo").addClass("hidden");
        $("#nombreTarjetaCompra").attr("required", true)
        tipoPago = "card";
    }

    function oxxoPay() {
        $("#pagoTarjeta").addClass("hidden");
        $("#pagoDepositoOxxo").removeClass("hidden");
        $("#nombreTarjetaCompra").attr("required", false)
        tipoPago = "oxxo";
    }

    function denthereumPay() {
        $("#pagoDepositoOxxo").addClass("hidden");
        $("#pagoDenthereum").removeClass("hidden");
        $("#pagoTarjeta").addClass("hidden");
     
        tipoPago = "denthereum";
    }

    const handlePayDenthereum = async ()=>{
        setLoadingPayment(true)
        setMensajeTransaccionDenthereum('Confirma la transacción')
        console.log(props.precioUSD)
        let precioDTH = parseFloat(precioDenthereum);
        const _totalDth = BigInt(precioDTH * 1e18);
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const signer = provider.getSigner()
        const contract = new ethers.Contract(address[56], abi, signer)
        contract.transfer(process.env.REACT_APP_WALLET,_totalDth)
        .then(tr =>{
            console.log(tr)
            setMensajeTransaccionDenthereum('Confirmando en la blockchain')
            setBlockHash(tr.hash)
            tr.wait().then(receipt=>{
                setMensajeTransaccionDenthereum('Compra completada')
            })
        })
        .catch(e=>{
            setLoadingPayment(false)
            if(e.code !== 'ACTION_REJECTED')
                Swal.fire({
                    icon:'warning',
                    title: 'BNB gas o DTH insuficiente',
                    timer: 1500,
                    showConfirmButton:false
                })
        })
    }

    // Una vez aceptada la transaccion, escuchamos el evento de compra
    // Para validar si se hizo correctamente todo
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const _contract = new ethers.Contract(address[56], abi, provider)
    _contract.on("Transfer", (from, to, amount) => {
        // Validamos que la transferencia recien hecha es justo de las
        // direcciones que deben ser y la cantidad que debe ser
        let precioDTH = parseFloat(precioDenthereum);
        const _totalDth = BigInt(precioDTH * 1e18);
        setTimeout(()=>{
            if(_totalDth !== undefined && amount !== undefined && from !== undefined && account !== undefined && to !== undefined)
                if(
                    (_totalDth.toString() === amount.toString()) && 
                    (from.toString() === account.toString()) &&
                    (to.toString() === process.env.REACT_APP_WALLET)
                ){
                    if(blockHash.length > 0){
                        let correo = localStorage.getItem('correoUser')
                        procesoCompletado(true, "denthereum", correo, blockHash, idCurso);
                        props.getMyBalanceDTH()
                    }
                } 
        },1000)
    });

    return (
        <div id="pago-part" className="content" role="tabpanel" aria-labelledby="pago-part-trigger" animation="true">


            {
                (proceso === 'ticket') ?
                    <div className="border rounded p-4 my-5 text-center">
                        <div>
                            <i className="fas fa-hourglass-half iconoPagoCheckout mt-3 mb-4"></i>
                        </div>
                        <span><b>Pago pendiente</b></span>
                        <hr className="hrCheckout my-4" />
                        <span>Hemos enviado el ticket para pagar a tu correo electrónico.</span>
                        <p className="mb-5"><b>Tienes hasta 3 días para realizar tu pago</b></p>
                        <a id="verTicketCheckout" className="btn btn-light w-100 btn-block" target="_blank">VER TICKET DE PAGO</a>
                    </div>

                    :
                    (proceso === 'realizado') ?

                        <div className="border rounded p-4 my-5 text-center">

                            <div>
                                <i className="fas fa-check-circle iconoPagoCheckout mt-3 mb-4"></i>
                            </div>

                            <span><b>¡Pago realizado con éxito!</b></span>

                            <hr className="hrCheckout my-4" />

                            <p>¡Felicidades! Ya diste un enorme paso para mejorar tu carrera profesional.</p>

                            <button id="verCursoCheckout" type="button" className="btn btn-light btn-block w-100 mt-5" onClick={verCurso}>Finalizar</button>
                        </div>
                        :
                        <div className="border rounded p-4 my-5 text-center">

                            <span className="mb-2"><b>Selecciona una forma de pago:</b></span>
                            <div onClick={cardPay} className="botonCheckoutCredito sombraBotonesCheckout mt-4">
                                <i className="fas fa-credit-card iconoBottonCheckout"></i>Crédito o débito
                            </div>
                            <div id="pagoTarjeta" className="fade show">
                                <div className="my-2">

                                    <div className="espacioPago my-4">

                                        <p className="text-muted mb-4">Ingresa tus datos de pago:</p>

                                        <div className="estiloInput mb-2">
                                            <input type="text" id="nombreTarjetaCompra" className="nombreTarjetaCompra form-control borderInput" placeholder="Nombre del titular de la tarjeta" required />
                                        </div>

                                        <form id="payment-form" onSubmit={handleSubmit}>

                                            <CardElement className="inputTarjeta" id="card-element"/>

                                            <button className="mb-5 mt-4" type="submit" disabled={!stripe}>
                                                PAGAR <span id="precioBoton"> $ {costoTotal && costoTotal} </span><span>{props.divisa}</span>
                                            </button>

                                        </form>

                                    </div>

                                </div>
                            </div>

                            
                            { props.divisa === "MXN" &&
                                <div onClick={oxxoPay} className="botonCheckoutEfectivo sombraBotonesCheckout">
                                    <i className="fas fa-money-bill iconoBottonCheckout"></i>Efectivo
                                </div>
                            }
                            { props.divisa === "MXN" &&
                                <form id="pagoDepositoOxxo" onSubmit={handleSubmit} className="hidden show fade">
                                    <div className="estiloInput mb-2">
                                        <input type="text" id="nombreDepositoOxxo" className="nombreTarjetaCompra form-control borderInput" placeholder="Nombre completo" required />
                                    </div>
                                    <div className="estiloInput mb-2">
                                        <input type="email" id="correoEnvioTicketOxxo" className="nombreTarjetaCompra form-control borderInput" placeholder="Correo para enviar el voucher" required />
                                    </div>
                                    
                                    <div className="espacioPagoOxxo mt-3 mx-2">
                                        <p id="card-error-oxxo" className="alert alert-warning hidden" role="alert">error</p>
                                        <p className="text-muted">Tendrás sólo <b>3 días</b> para realizar el pago en el OXXO más cercano. Una vez se acredite tu pago te notificaremos al correo electrónico que nos proporcionaste.</p>
                                    </div>

                                    <button className="mb-5 mt-4 btnPagar">
                                        <span className="button-text">PAGAR <span id="precioBotonOxxo"> $ {costoTotal && costoTotal} </span><span>{props.divisa}</span></span>
                                    </button>
                                </form>
                            }

                            <button className="botonCheckoutDenthereum sombraBotonesCheckout"
                                onClick={denthereumPay}
                            >
                                <img src={iconoLogo} className='referenciaCursodontic__img' />
                                Denthereum <span className='font-size-s'>| 5% Descuento</span>
                            </button>

                            <div id="pagoDenthereum" className="fade show hidden">
                                <div className="my-2">

                                    <div className="espacioPago my-4">

                                        <p className="text-muted mb-4">
                                            Pago anónimo y sin restricciones.
                                        </p>
                                        <p className='font-bold'>
                                            Te descontamos el 5% pagando con Denthereum 🎖🦷
                                        </p>
                                        <div className='d-flex justify-content-center align-items-center mt-3'>
                                            <span className='mx-3'>
                                                Antes: <strike>${`${costoTotalUSD}`}</strike>
                                                <small>
                                                    USD
                                                </small>
                                            </span>

                                            <span className='font-bold'>
                                                Ahora: ${`${(parseFloat(costoTotalUSD)*0.95).toFixed(2)}`}
                                                <small>
                                                    USD
                                                </small>
                                            </span>
                                        </div>
                                        {
                                        (active) &&
                                            <button 
                                                onClick={handlePayDenthereum} 
                                                className="mb-3 mt-4 botonCheckoutDenthereum  font-bold" 
                                                type="submit" 
                                                disabled={loadingPayment || (props.saldoTokens < precioDenthereum)}
                                            >   
                                                {
                                                    (loadingPayment) && 
                                                    <>
                                                        <ReactLoading type="spin" color="#FFF" height={'25px'} width={'25px'} className="circularBarProgressIndicatorItem" />
                                                        <span className='ml-2'>{mensajeTransaccionDenthereum}</span>
                                                    </>
                                                }
                                                {
                                                    !loadingPayment &&
                                                        (props.saldoTokens >= precioDenthereum) &&
                                                            <>
                                                                PAGAR<span id="precioBoton"> {precioDenthereum}DTH</span>
                                                            </>
                                                        
                                                }
                                                {
                                                    !loadingPayment && (props.saldoTokens < precioDenthereum) &&
                                                        <>
                                                            DTH INSUFICIENTE
                                                        </>
                                                }
                                            </button>
                                        }

                                        <div className={`${(active) && 'hidden'} mb-3`}>
                                            <p className="text-muted font-size-s mt-3">
                                                Para continuar, debes conectar tu wallet:
                                            </p>
                                            <ButtonConnectWallet 
                                                handleLogin={props.handleLogin}
                                            />
                                        </div>

                                        <Link to='/denthereum/comprar' className='comprarDenthereum'>
                                            Compra Denthereum aquí
                                        </Link>
                                            

                                    </div>

                                </div>
                            </div>

                            {/* <div className="botonCheckoutTransfer sombraBotonesCheckout">
                                <i className="fas fa-exchange-alt iconoBottonCheckout"></i>Transferencia
                            </div>
                            <div className="botonCheckoutPaypal sombraBotonesCheckout">
                                <i className="fab fa-paypal iconoBottonCheckout"></i>Paypal
                            </div>
                            <div className="botonCheckoutMP sombraBotonesCheckout">
                                <img className="iconoBottonCheckout" src={MPLogo} alt="mercado pago" height="25"/>Mercado Pago
                            </div>
                            <div className="botonCheckoutBitcoin sombraBotonesCheckout">
                                <i className="fab fa-bitcoin iconoBottonCheckout"></i>Bitcoin
                            </div> */}
                        </div>

            }

            <div className="text-center mb-5">
                <span className="note"><b>Pago 100% seguro, protegemos tus datos</b></span>
            </div>
        </div>
    );
}

function WrapperCarito(props){
    return(
        <Elements stripe={stripePromise}>
            <PagoCarrito urlCurso={props.urlCurso} divisa={props.divisa} curso={props.curso} proceso={props.proceso} idUsuario={props.idUsuario} direccion={props.direccion} setFinalized={props.setFinalized} getMyBalanceDTH={props.getMyBalanceDTH} handleLogin={props.handleLogin} saldoTokens={props.saldoTokens} />
        </Elements>
    );
}

export { WrapperCarito };