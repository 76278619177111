import React from 'react';
import { Link } from 'react-router-dom';

export default function CardCursoMuestraMembresia({ titulo, instructor, linkCurso, imagenCurso }){

    function setURL(url) {

        let urlAux = url.split("/");
        let urlFinal = urlAux[3];

        return urlFinal;

    }

    return(
        <Link to={`cursos/${setURL(linkCurso)}`}>
            <div className="card card-block cursos__card">
                <img className="card-img-top img-fluid imagenPrincipal cursos__cardImagen" src={imagenCurso}/>
                <div className="card-body cursos__cardDetalles text-left enlaces">
                    <p className="cursos__cardTitulo font-weight-bold text-white pr-2">
                        { titulo }
                    </p>
                    <p className="cursos__cardInstructor font-weight-bold pr-2 my-3">
                        Un curso de { instructor }
                    </p>
                </div>
            </div>
        </Link>
    )
}