import React, { useState, useEffect } from 'react';
import './pickColors.css';

import getColors from '../../services/getColors.js';
import addItemCart from '../../services/addItemCart.js';
import Swal from 'sweetalert2'
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';

const PickColors = (props) => {

    const [remainingColor, setRemainingColor] = useState([]);
    const [remainingColorAux, setRemainingColorAux] = useState([]);
    const[colores, setColores] = useState([]);
    let objectValues;
    let idUsuario = localStorage.getItem('idUsuario');

    useEffect(() => {
        window.scrollTo(0, 0);

        let cantidadPaquetes = $('#selectColores').val();

        let cantidadAcetatos = cantidadPaquetes * 20;

        setRemainingColor(cantidadAcetatos);
        setRemainingColorAux(cantidadAcetatos);

        getColorsData();

    } , []);

    function onClickPlus(idColor) {

        let colorSelected = document.querySelector('#color-'+idColor).value;

        if(remainingColor > 0 && colorSelected < remainingColorAux) {
            
            let total = remainingColor - 1;

            setRemainingColor(total)

            document.querySelector('#color-'+idColor).value = parseInt(colorSelected)+1;

        }

    }

    function onClickMinus(idColor) {
        
        let colorSelected = document.querySelector('#color-'+idColor).value;

        if(colorSelected === 0) {
        } else if(remainingColor < remainingColorAux && colorSelected > 0) {

            let total = remainingColor + 1;
            setRemainingColor(total);
            document.querySelector('#color-'+idColor).value = parseInt(colorSelected)-1;

        }

    }

    function onClickSendButton(e) {
        e.preventDefault();

        Swal.showLoading();
        
        var dataColors = [];

        if(remainingColor === 0){
            
            let inputColors = document.querySelector('#colores').getElementsByTagName('input');

            for( var i = 0; i < inputColors.length; i++ ){

                if(inputColors[i].value > 0){

                    let dato = (inputColors[i].getAttribute('id')).split('color-')[1]+"-"+inputColors[i].value;

                    dataColors.push(dato);
                }
            }

            let values = new FormData();
    
            values.append("idUsuario",idUsuario);
            values.append("producto", props.producto);
            values.append("coloresAcetatos", JSON.stringify(dataColors));
        
            objectValues = Object.fromEntries(values);
            addItemToCart();
        } else {
            Swal.fire(
                'Error',
                'Por favor, agrega la cantidad de acetatos restante',
                'error'
            )
        } 
    }

    function onClickUpdateCounter(){
        let cantidadPaquetes = $('#selectColores').val();

        let cantidadAcetatos = cantidadPaquetes * 20;

        $('#colores').find("input").val(0);

        setRemainingColor(cantidadAcetatos);
        setRemainingColorAux(cantidadAcetatos);
    }

    function getColorsData(){

        getColors().then((response) => {
          let _response = JSON.parse(response);

          if(_response.response === "error"){
            Swal.fire(
                'Ocurrió un error',
                'Revisar la conexión a internet e inténtalo de nuevo.',
                'error'
            )
          } else {
            setColores(_response.colores);
          }
        }).catch((error) => {
          Swal.fire(
              'Error del servidor',
              'Inténtalo de nuevo en unos momentos.',
              'error'
          )
        })
    }

    function addItemToCart(){
        addItemCart({values:objectValues}).then((response) => {
            let _response = JSON.parse(response);
            if(_response.response === "error"){
                Swal.fire(
                    'Ocurrió un error',
                    'Revisar la conexión a internet e inténtalo de nuevo..',
                    'error'
                )
            } else {
                Swal.close();
                props.setModalShow()
                // Swal.fire({
                //     icon: 'success',
                //     title: 'Producto agregado a tu carrito',
                //     showConfirmButton: false,
                //     timer: 1500
                // }).then(() => {
                //     window.location.reload();
                // });
            }
          }).catch((error) => {
            Swal.fire(
                'Error del servidor',
                'Inténtalo de nuevo en unos momentos..',
                'error'
            )
        })
    }


  return (
    <div>

        <button type="button" className="btn btn-primary w-100" data-toggle="modal" data-target="#modalColores" onClick={onClickUpdateCounter}>
        SELECCIONA TUS COLORES
        </button>

        <div className="modal fade" id="modalColores" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">

            <div className="modal-dialog modal-lg">
                
                <div className="modal-content px-4">

                    <div className="modal-header d-flex justify-content-center p-0" style={{marginTop: '20px', borderStyle: 'none'}}>

                        <div className="color-modal-title">
                            <h5 className="modal-title" id="exampleModalLabel">Arma tu pedido mixto</h5>
                        </div>

                        <div className="my-2">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" className="fs-2">&times;</span>
                            </button>
                        </div>

                    </div>

                    <div className="modal-body p-0">

                        <div className="text-center font-regular font-size-s text-gray-color">Coloca la cantidad de acetatos que deseas por cada color</div>

                        <div className="text-center" style={{marginTop: '30px'}}>
                            <h2 className="numeroAcetatosPorSeleccionar text-danger">{ remainingColor && remainingColor }</h2>
                            <p className="palabraRestantes">Acetatos restantes</p>
                        </div>

                        <div className="container-fluid">

                            <div id="colores">

                                {colores.map(color => (
                                    <div className="form-group row color my-4" key={ color.id_color } >

                                        <div className="d-flex align-items-center col-md-7 col-sm-7 col-6 col-form-label">
                                            <div className="row">
                                                <img src={color.rutaImagenColor} alt={color.nombreColor} width="10%" className="img img-fluid" />
                                                <span className="text-muted text-center nombreColor">{color.color}</span>
                                            </div>
                                        </div>

                                        <div className="d-flex align-items-center col-md-5 col-sm-5 col-6">

                                            <div className="input-group-append">
                                                <button className="btn btn-decrement btn-outline-secondary" onClick={ () => onClickMinus(color.id_color) }>
                                                    <strong>-</strong>
                                                </button>
                                            </div>

                                            <input id={`color-${color.id_color}`} type="number" name="cantidadAcetatosColor" className="form-control text-center input-colors-amount" defaultValue={0} min="0" max="200" step="1" readOnly/>

                                            <div className="input-group-append">
                                                <button className="btn btn-increment btn-outline-secondary" onClick={ () => onClickPlus(color.id_color) }>
                                                    <strong>+</strong>
                                                </button>
                                            </div>

                                        </div>

                                    </div>
                                ))}
                                
                            </div>

                            <div className="text-center">
                                <h2 className="numeroAcetatosPorSeleccionar text-danger">{ remainingColor }</h2>
                                <p>Acetatos restantes</p>
                            </div>

                        </div>

                        <div className="container btnContinuarInfo">
                            <div className="alertaAcetatos"></div>
                            <div>
                                {props.btnCompra}
                            </div>
                            <div data-dismiss="modal" onClick={idUsuario !== null && idUsuario !== undefined && onClickSendButton}>
                                {props.btnCarrito}
                            </div>
                            {/* <div className="row">
                                <div className="col-2"></div>
                                <div className="col-8">
                                    <a onClick={ onClickSendButton } id="colorSelectedButton" className="btn btn-block btn-success mt-4 btnVerificarCantidadColores">Continuar <i className="fas fa-arrow-circle-right mx-2"></i></a>
                                </div>
                                <div className="col-2"></div>
                            </div> */}
                        </div>

                    </div>

                </div>

            </div>
            
        </div>

    </div>
  )
}

export default PickColors;