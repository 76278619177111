import React from 'react';

import './classComments.css';

import LeaveComment from '../leaveComment/LeaveComment';
import ClassCommentMade from '../classCommentMade/ClassCommentMade';
import $ from 'jquery';
import Swal from 'sweetalert2'
import addClassComment from "./../../services/addClassComment.js";

function ClassComments(props) {

    let idUsuario = localStorage.getItem('idUsuario');

    const [list, commentsList] = React.useState([]);

    const onSubmitComment = (e) => {
        e.preventDefault();

        Swal.showLoading();
        
        let values = new FormData(e.target)
        let comentario = $("#textAreaComentarioClase").val();
        values.append("comentario",comentario);
        values.append("idUsuario",idUsuario);
        values.append("idClase",props.clase);
        values.append("accion","agregarComentario");
        let objectValues = Object.fromEntries(values);

        if(comentario.trim().length > 0){
    
            addClassComment({values:objectValues}).then((response) => {
                let _response = JSON.parse(response);
                console.log(_response);
                if(_response.response === "success"){

                    $("#textAreaComentarioClase").val("");
        
                    commentsList(list.concat(
                        <ClassCommentMade 
                            key={_response[0].idComentario}
                            idComentario={_response[0].idComentario}
                            nombre={_response[0].nombre}
                            foto={_response[0].fotoPerfil}
                            comentario={comentario}
                            fecha={"un momento"}
                            likes={0}
                            respuestas={[]}
                        />
                    ));
        
                    Swal.fire({
                        icon: 'success',
                        title: 'Comentario agregado',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Ocurrió un error',
                        text: 'Revisar la conexión a internet e inténtalo de nuevo.',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            }).catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error del servidor',
                    text: 'Inténtalo de nuevo en unos momentos.',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
        }else {
            Swal.fire({
                icon: 'error',
                title: 'Ingresa tu comentario',
                showConfirmButton: false,
                timer: 1500
            })
        }
    }

  return (
    <div className="">
        <div className="row">
            <div className="col-12 p-4">
                <LeaveComment foto={props.foto} clase={props.clase} onSubmitComment={onSubmitComment}/>
            </div>
            <div className="col-12 pt-3">
                <h1 className="font-bebas dashboard-name">{ props.comentarios.length } COMENTARIOS</h1>
            </div>
            <div id="divComentariosClase" className="col-12 pt-4">
                {props.comentarios.map(comentario => (
                    <ClassCommentMade 
                        key={comentario.idComentario}
                        idClase={props.clase}
                        idComentario={comentario.idComentario}
                        nombre={comentario.nombreUsuario}
                        foto={comentario.foto}
                        comentario={comentario.comentario}
                        fecha={comentario.fecha}
                        likes={comentario.likes}
                        liked={comentario.liked}
                        fotoUsuarioActual={props.foto}
                        respuestas={comentario.respuestas}
                    />
                ))}
                {list}
            </div>
        </div>
    </div>
  )
}

export default ClassComments;