import React from 'react';

import './Cursos.css';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import {ResultadosItem} from '../ResultadosItem/ResultadosItem.js';

function Cursos({cursos, titulo, darkMode}){

    const state= {
        responsive:{
            0: {
                items: 1,
                stagePadding: 20
            },
            576: {
                items: 2,
                stagePadding: 20
            },
            768: {
                items: 2,
                stagePadding: 30
            },
            992: {
                items: 3,
                stagePadding: 35
            },
            1200: {
                items: 4,
                stagePadding: 35
            },
        },
    }


    return(
        <div className="infoCursoCursos" >

            <p className="bebas mt-4 mb-3 titulos">{ titulo }</p>
            {cursos.length >= 1 &&

                <OwlCarousel className="owl-theme"
                margin={10}
                autoplay={false}
                loop={true}
                dots={false}
                touchDrag={true}
                animateIn={true}
                responsive={state.responsive}
                center={false}
                lazyLoad={true}
                >
                        {cursos.map(curso => (
                            <div className="item" key={curso.idCurso}>
                                <ResultadosItem 
                                    key={curso.idCurso} 
                                    clase={'rosaColor'}
                                    tipo={'CURSO'}
                                    url={curso.imagenCurso}
                                    nombre={curso.tituloCurso}
                                    cripto={curso.precioCurso}
                                    fiat={curso.precioCurso}
                                    urlPage={curso.urlPage}
                                    darkMode={darkMode}
                                    divisa={curso.divisa}
                                    type="curso"
                                    />
                            </div>

                        ))}
                </OwlCarousel>
            }

        </div>
    );
}

export {Cursos};