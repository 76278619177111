import React from 'react';
import { useHistory } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'

import './InicioSesionModal.css';

import loginSocialMedia from "./../../services/loginSocialMedia";
import SignUpSocialMedia from "./../../services/SignUpSocialMedia";
import loginEmail from "./../../services/loginEmail";

import {CrearCuentaModal} from '../CrearCuentaModal/CrearCuentaModal';
import {RecuperarPassModal} from '../RecuperarPassModal/RecuperarPassModal.js';

import Swal from 'sweetalert2'
import $ from 'jquery';
import { useWeb3React } from '@web3-react/core';

//FIREBASE
import  firebase  from '../../utils/Firebase/firebase.js';
import ButtonConnectWallet from '../ButtonConnectWallet';
import { sendCompleteRegistration } from '../../utils/facebookPixelEvents';

function InicioSesionModal({show, setModalLogin, handleLogin}){

    const [showModalRegistro, setShowModalRegistro] = React.useState(false);

    const { deactivate } = useWeb3React()
    
    const history = useHistory()
    const auth = firebase.auth
    const provider = new firebase.auth.GoogleAuthProvider();
    const providerFB = new firebase.auth.FacebookAuthProvider();

    let values = new FormData();

    const loginGoogle = () => {
        Swal.showLoading();
        auth().signInWithPopup(provider)
          .then(({ user }) => {

            values.append("uid",user.uid);
            values.append("tipoLogin", "google");
            values.append("fotoPerfilRedes", user.photoURL);

            let objectValues = Object.fromEntries(values);

            loginSocialMedia({values:objectValues}).then((response) => {
                let _response = JSON.parse(response);
                if(_response.response === "error"){

                    values.append("nombreUsuario", user.displayName);
                    values.append("correo", user.email);
                    values.append("especialidad", "sin especialidad");
                    values.append("medioRegistro", "web")
                    values.append("fotoPerfilRedes", user.photoURL);

                    objectValues = Object.fromEntries(values);

                    SignUpSocialMedia({values:objectValues}).then((response) => {
                        let _response = JSON.parse(response);
                        if(_response.response === "error"){
        
                            Swal.fire({
                                icon: 'error',
                                title: 'La cuenta ha sido desactivada o hubo un error',
                                text: 'Inténtalo de nuevo, si el problema persiste contáctanos'
                            })
        
                        } else {
                            try{
                                // Swal.close()
                                _response.email = user.email
                                _response.fotoPerfil = user.photoURL
                                handleLogin(_response)
                                sendCompleteRegistration(_response.nombre,_response.correo,null);
                            } catch (err){
                
                            }
        
                        }
                    }).catch((error) => {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error del servidor',
                            text: 'Inténtalo de nuevo en unos momentos.',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    })

                } else {
                    try{
                        _response.email = user.email
                        _response.fotoPerfil = user.photoURL
                        handleLogin(_response)
                    } catch (err){
        
                    }

                }
                // Swal.hideLoading();
            }).catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error del servidor',
                    text: 'Inténtalo de nuevo en unos momentos.',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
            deactivate()
        }).catch(err=>{
            Swal.fire({
                icon: "info",
                title: "Permisos rechazados",
                text: "Puedes intentarlo de nuevo y conectarte",
                showConfirmButton: false,
                timer: 2000,
            })
        })
    }

    const loginFacebook = () => {
        Swal.showLoading();
        auth().signInWithPopup(providerFB)
          .then(({ user }) => {

            values.append("uid",user.uid);
            values.append("tipoLogin", "facebook");
            values.append("fotoPerfilRedes", user.photoURL);

            let objectValues = Object.fromEntries(values);

            loginSocialMedia({values:objectValues}).then((response) => {
                let _response = JSON.parse(response);
                if(_response.response === "error"){

                    values.append("nombreUsuario", user.displayName);
                    values.append("correo", user.email);
                    values.append("especialidad", "sin especialidad");
                    values.append("medioRegistro", "web")
                    values.append("fotoPerfilRedes", user.photoURL);

                    objectValues = Object.fromEntries(values);

                    SignUpSocialMedia({values:objectValues}).then((response) => {
                        let _response = JSON.parse(response);
                        if(_response.response === "error"){
        
                            Swal.fire({
                                icon: 'error',
                                title: 'La cuenta ha sido desactivada o hubo un error',
                                text: 'Inténtalo de nuevo, si el problema persiste contáctanos',
                                showConfirmButton: false,
                                timer: 1500
                            })
        
                        } else {
                            try{
                                _response.email = user.email
                                _response.fotoPerfil = user.photoURL
                                handleLogin(_response);
                                sendCompleteRegistration(_response.nombre,_response.correo,null);
                            } catch (err){
                
                            }
        
                        }
                    }).catch((error) => {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error del servidor',
                            text: 'Inténtalo de nuevo en unos momentos.',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    })

                } else {
                    try{
                        _response.email = user.email
                        _response.fotoPerfil = user.photoURL
                        handleLogin(_response)
                    } catch (err){
        
                    }

                }
                // Swal.hideLoading();
            }).catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error del servidor',
                    text: 'Inténtalo de nuevo en unos momentos.',
                })
            })
            deactivate()
        }).catch(err=>{
            Swal.fire({
                icon: "info",
                title: "Permisos rechazados",
                text: "Puedes intentarlo de nuevo y conectarte",
                showConfirmButton: false,
                timer: 2000,
            })
        })
    }

    const loginByEmail = (e) => {

        e.preventDefault();

        Swal.showLoading();

        let values = new FormData();

        let correo = $("#correoInicioSesion").val();
        let pass = $("#passInicioSesion").val();

        values.append("correo", correo);
        values.append("password", pass);

        let objectValues = Object.fromEntries(values);

        loginEmail({values:objectValues}).then((response) => {
            let _response = JSON.parse(response);
            if(_response.response === "error"){
                Swal.fire(
                    'Ocurrió un error',
                    'Revisa tus datos e inténtalo nuevamente.',
                    'error'
                )
            } else {
                try{
                    _response.email = correo
                    handleLogin(_response)
                    Swal.hideLoading();
                } catch (err){
    
                }
            }
            deactivate()
        }).catch((error) => {
            Swal.fire(
                'Error del servidor',
                'Inténtalo de nuevo en unos momentos.',
                'error'
            )
        })
    }

    const queEsUnaWallet = () => {
        setModalLogin(false)
        history.push('/denthereum')
    }

    const handleShow = () => {
        setModalLogin(false)
        setShowModalRegistro(true);
    };

    return (
        <>

            <Modal id="login-modal" show={show} onHide={setModalLogin}>

                <Modal.Header className="modalInicioSesion_header titulo bebas" closeButton>

                    <p className="bebas modalInicioSesion_header-titulo">INICIAR SESIÓN</p>

                </Modal.Header>

                <Modal.Body className="modalInicioSesion_body">
                    
                    <div className='p-3'>

                        <button 
                            onClick={handleShow} 
                            type="button" 
                            className="btn w-100 btn-light modalInicioSesion_body-correo my-2 shadow-sm"
                        >
                                <i className="fas fa-envelope modalInicioSesion_body-icono"></i>
                                <span>Continuar con mi correo</span>
                        </button>
                        
                        <button 
                            onClick={loginGoogle} 
                            type="button" 
                            className="btn w-100 btn-light modalInicioSesion_body-google my-2 shadow-sm"
                        >
                                <i className="fab fa-google modalInicioSesion_body-icono"></i>
                                <span>Continuar con Google</span>
                        </button>
                        
                        <button 
                            onClick={loginFacebook} 
                            type="button" 
                            className="btn  w-100 modalInicioSesion_body-facebook my-2 shadow-sm"
                        >
                                <i className="fab fa-facebook-f modalInicioSesion_body-icono"></i> 
                                <span>Continuar con Facebook</span>
                        </button>
                        
                        <ButtonConnectWallet 
                            handleLogin={handleLogin}
                        />

                        <div className='d-flex justify-content-end'>
                            <button 
                                onClick={queEsUnaWallet} 
                                className='modalInicioSesion_body-aprende btn btn-sm text-muted'
                            >
                                Qué es una wallet?
                            </button>
                        </div>

                    </div>

                    <div className='modalInicioSesion_body-form p-3'>

                        <p>O inicia sesión con tu email</p>

                        <form id="formularioInicioSesion" onSubmit={ loginByEmail}>

                            <div className="form-group my-3">
                                <input type="email" className="form-control" id="correoInicioSesion" aria-describedby="email" placeholder="Email o celular" required/>
                            </div>

                            <div className="form-group">
                                <input type="password" className="form-control" id="passInicioSesion" placeholder="Contraseña" required/>
                            </div>

                            <RecuperarPassModal/>

                            <button type="submit" className="btn btn-light btn-lg w-100 py-2 modalInicioSesion_body-botonCompra my-2">ENTRAR</button>

                        </form>

                    </div>

                </Modal.Body>

            </Modal>

            <CrearCuentaModal handleLogin={handleLogin} showModal={showModalRegistro} setShowModal={setShowModalRegistro}/>
        </>
    );
}

export {InicioSesionModal};