import React from 'react';
import Swal from 'sweetalert2'
import getResults from "../../services/getResults.js";
import { ResultadosItem } from '../ResultadosItem/ResultadosItem';
import { useParams } from 'react-router-dom';
import ReactLoading from 'react-loading';
import './ResultadosBusqueda.css';

function ResultadosBusqueda() {

    // Esta es la variable donde obtenemos el término de búsqueda del usuario
    let { busqueda } = useParams()

    const url = window.location.href;

    let objectValues;

    const [parametro, setParam] = React.useState('');

    const [populares, setPopulars] = React.useState([]);
    const [materiales, setMateriales] = React.useState([]);

    const [errorMSG, setErrorMSG] = React.useState(true);

    React.useEffect(() => {
        window.scrollTo(0, 0);
        try {
            const serializedState = localStorage.getItem('idUsuario');
            
            let titulo = document.getElementById("tituloWeb");
            titulo.innerHTML = 'Buscar: '+busqueda;
            
            setParam(busqueda);
            
            let values = new FormData();
            
            if(serializedState !== null){
                values.append("idUsuario", serializedState);
            }
            values.append("terminoBusqueda", busqueda);

            objectValues = Object.fromEntries(values);

            getResultados();
        } catch (err) {
            console.log(`peto: ${err}`)
        }
    }, [url]);


    function getResultados() {
        // Seteamos valores para que aparezca el loading
        setErrorMSG(true)
        setPopulars([])
        setMateriales([])

        getResults({ values: objectValues }).then((response) => {
            console.log(response)
            let _response = JSON.parse(response);

            if (_response.response === "error") {
                setErrorMSG(false);
            } else {
                setPopulars(_response.resultadosCursos);
                setMateriales(_response.resultadosMateriales);
            }
        }).catch((error) => {
            Swal.fire(
                'Error del servidor',
                'Inténtalo de nuevo en unos momentos.',
                'error'
            )
        }
        )
    }


    return (
        <div>

            {materiales.length >= 1 || populares.length >= 1 ?


                <div className="container pt-4">

                    <p className="mb-4"><b>{(populares && populares.length) + (materiales && materiales.length)}</b> resultados para <b>{parametro && parametro}</b></p>

                    <div className="row">

                        {populares.length >= 1 && <p className="labelResult">Cursos</p>}

                        {populares.length >= 1 &&

                            populares.map(curso => (
                                <div className="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3" key={curso.id_curso}>
                                    <div className="item my-3">
                                        <ResultadosItem
                                            clase={'rosaColor'}
                                            tipo={'CURSO'}
                                            url={curso.imagenCurso}
                                            nombre={curso.tituloCurso}
                                            cripto={24.891}
                                            fiat={curso.precio}
                                            divisa={curso.divisa}
                                            urlPage={curso.urlCorto}
                                            type="curso"
                                        />
                                    </div>
                                </div>
                            ))

                        }

                        {materiales.length >= 1 && <p className="labelResult">Materiales</p>}

                        {materiales.length >= 1 &&

                            materiales.map(material => (
                                <div className="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3" key={material.id_producto}>
                                    <div className="item my-3">
                                        <ResultadosItem
                                            clase={'verdeColor'}
                                            tipo={'MATERIAL'}
                                            url={material.imagenProducto}
                                            nombre={material.nombreProducto}
                                            cripto={24.891}
                                            fiat={material.precio}
                                            divisa={material.divisa}
                                            urlPage={material.urlPage}
                                            type="material"
                                        />
                                    </div>
                                </div>
                            ))

                        }

                    </div>

                </div>

                :

                errorMSG === false ?

                <div className="text-center textErrorMSG">
                    🧐
                    <p>No se encontraron resultados para: <span className='font-weight-bold'>{parametro}</span></p>
                </div>
                :

                <div className="circularBarProgressIndicator">
                    <ReactLoading type="spin" color="#F65F5D" height={'50px'} width={'50px'} className="circularBarProgressIndicatorItem mb-3" />
                    Buscando...
                </div>

            }
        </div>

    );

}


export { ResultadosBusqueda };