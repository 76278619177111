import React from 'react'
import './ButtonObtenerDenthereum.css'
import Swal from 'sweetalert2'
import liberarRecompensaToken from './../../services/liberarRecompensaToken'

export default function ButtonObtenerDenthereum(props){

    const formatBlanaces = Intl.NumberFormat('en-US', {
        maximumFractionDigits: 2
    });

    const [recompensaObtenida, setRecompensaObtenida] = React.useState(props.obtuvoRecompensa)
    const [tokensObtenidos, setTokensObtenidos] = React.useState(props.numeroTokensObtenidos)

    const getRecompensaToken = () =>{

        Swal.fire({
            icon:'warning',
            text: 'Tus denthereum se calculan en base al precio actual de la criptomoneda y el valor del curso',
            showCancelButton: true,
            confirmButtonText: 'Entiendo, quiero mis DTH',
        }).then((result) => {
            Swal.showLoading()
            if(result.isConfirmed){
                let data = { idUsuario : props.idUsuario, idCurso : props.idCurso }
                console.log(data)
                liberarRecompensaToken({values:data})
                    .then(respuesta => {
                        console.log(respuesta)
                        let _respuesta = JSON.parse(respuesta)
                        if(_respuesta.response === 'success'){
                            setTimeout(()=>{
                                setRecompensaObtenida(true)
                                setTokensObtenidos(_respuesta.recompensa_token)
                                props.setIngresoTokens(parseFloat(props.ingresoTokens)+parseFloat(_respuesta.recompensa_token))
                                Swal.fire({
                                    icon: "success",
                                    title: "Felicidades",
                                    text: `Ganaste ${_respuesta.recompensa_token} DTH, puedes verlo en tu dashboard`,
                                    showConfirmButton: false,
                                    timer: 3000,
                                });
                            }, 10)
                        } else {
                            Swal.fire(
                                'Ooops..',
                                _respuesta.mensaje,
                                'error'
                            )
                        }
                    })
            }
        })
        
    }

    const avisoRecompensaObtenida = () =>{
        Swal.fire({
            icon: "info",
            text: `Estos son los Denthereum que ganaste por culminar el curso, puedes ver el historial de tus ingresos en tu dashboard`,
        });
    }
    const avisoRecompensaMembresia = () =>{
        Swal.fire({
            icon: "info",
            text: `Tienes una membresia activa, por lo que para recibir tus ingresos en Denthereum debes finalizar al menos 10 cursos`,
        });
    }

    return(
        <>
        {
            recompensaObtenida && tokensObtenidos > 0 &&
                <button className='btn btn-outline-verde btn-sm btnObtenerRecompensa'
                    onClick={()=>avisoRecompensaObtenida()}
                >
                    Ganaste {formatBlanaces.format(tokensObtenidos)} DTM
                </button>
        }
        
        {
            !recompensaObtenida && props.derechoRecompensaCurso &&
                <button className='btn btn-amarillo btn-sm btnObtenerRecompensa' onClick={()=>getRecompensaToken()}>
                    Reclamar mis DTH
                </button>
        }
        
        {
            !recompensaObtenida && !props.derechoRecompensaCurso &&
                <button className='btn btn-amarillo btn-sm btnObtenerRecompensa'
                    onClick={()=>avisoRecompensaMembresia()}
                >
                    Termina {(props.numeroCursosFaltantesDeMembresia == 1 ? 'curso' : 'cursos')} más y gana DTH
                </button>
        }


        </>
    )
}