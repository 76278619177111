import React, { useState, useEffect }from 'react';
// import { Box, Grid, Card, CardContent, Container} from '@mui/material';
import './userCertificates.css';
import certificateLogo from '../../assets/icons/certificate-icon.svg';
import Certificate from '../certificate/Certificate';
import getCourses from '../../services/getCourses';
import Swal from 'sweetalert2';
import config from '../../config';

const UserCertificates = () => {

  let idUsuario = localStorage.getItem('idUsuario');
 
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    getUserCourses(idUsuario);
  }, []);

  function getUserCourses(idUsuario) {

    let formData = new FormData();

    formData.append('idUsuario', idUsuario);

    let url = config.urlApi+'controladores/misCursos.app.php';

    getCourses(formData,url).then(response => {
        let _respuesta = JSON.parse(response);
        setCourses(_respuesta);
      })
      .catch(error => {
      // Swal.fire(
      //     'Error del servidor',
      //     'Inténtalo de nuevo en uno momentos.',
      //     'error'
      // )
    })

  }

  return (
    <div className="progress-card certificates p-4">
        <div className="row d-flex justify-content-center">
            <div className="col-12 card-title">
                <h2 className="font-bebas color-azul">TUS CERTIFICADOS</h2>
            </div>
            
            {courses.length >= 1 ?
              <>
                <div>
                  <p>Recueda que para poder generar tu certificado es obligatorio que hayas completado el <strong>100%</strong> de las clases.</p>
                  <hr/>
                </div>
                {courses.map(course => (
                    <Certificate fotoCurso={course.fotoCurso} idCurso={course.idCurso} promedioVisto={course.promedioVisto} tituloCurso={course.tituloCurso} urlCurso={course.urlCurso} />
                  ))}
                </>
                :
                <>
                <div className="col-12 text-center my-3">
                  <img alt="certificate" className="certificate-icon" src={certificateLogo}/>
                </div>
                <div className="col-12 text-center">
                  <p className="first-certificate-title fs-4 font-semibold my-2">Obtén tu primer certificado</p>
                  <p className="font-light text-center first-certificate-text">Completando cualquiera de nuestros cursos</p>
                </div>
              </>
            }
        </div>
    </div>
  )
}

export default UserCertificates;