import React from 'react';
import './replyComment.css';

import { Avatar } from '@mui/material';

function ReplyComment (props) {
  return (
    <div className="reply-comment-container pt-3">
        <div className="row">
            <div className="col-2 d-flex justify-content-center">
                <Avatar 
                    src={"https://cursodontic.com/asset/img/fotosPerfil/"+props.foto}
                    sx={{ width: 50, height: 50 }} />
            </div>
            <div className="col-10 name-time-reply">
                <p className="font-bold">{props.nombre}</p>
                <p className="text-gray-color font-light my-3">Hace {props.fecha}</p>
                <div className="reply-comment-text">
                    <p className="font-size-s">{props.respuesta}</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ReplyComment;