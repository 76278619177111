import React, { useState, useEffect } from 'react';

import '../components/paginaPrincipal/paginaPrincipal.css';

import { Membresia } from '../components/Membresia/Membresia';
import { Cursos } from '../components/Cursos/Cursos';
import { Denthereum } from '../components/Denthereum/Denthereum';

import BasicLogoAmarillo from '../assets/images/BasicLogoDenthereumAmarillo.png';
import LogoAmarillo from '../assets/images/LogoDenthereumAmarillo.png';
import Internacional from '../assets/images/internacional.png';
import Grabacion from '../assets/images/grabacion.png';
import PreciosAsequibles from '../assets/images/preciosasequibles.png';

import { Link } from 'react-router-dom';

import config from '../config';
import getCourses from '../services/getCourses';

import $ from 'jquery';

export default function PaginaPrincipal(props){

    let idUsuario = localStorage.getItem('idUsuario');
    let usuarioMembresia = localStorage.getItem('statusMembresia');

    const [cursosPopulares, setCursosPopulares ] = useState([]);

    useEffect(() => {
        // let titulo = document.getElementById("tituloWeb");
        // titulo.innerHTML = 'Cursodontic';
        showMembership()
        window.scrollTo(0, 0)
        getPopularCourses()
        $('.membresia_flag').addClass('membresia_flag_pagina_principal').removeClass('membresia_flag')
        
    } , []);

    function getPopularCourses() {
        let formData = new FormData();

        formData.append('idUsuario', idUsuario);

        let url = config.urlApi+'controladores/cursosPopulares.app.php';

        getCourses(formData,url).then(response => {
            console.log({response})
            let _respuesta = JSON.parse(response);

            if(_respuesta.response === 'success'){
                setCursosPopulares(_respuesta.datos);
            }

        })
        .catch(error => {
            console.log(error);
        })
    }

    function showMembership() {
        if(usuarioMembresia !== '0' && usuarioMembresia !== null && usuarioMembresia !== undefined) {
            $('.alumnoPremium').addClass('hidden');
        } 
    }

    return(

        <div className="container-fluid paginaPrincipal">

            <div className="container noPadding-xs noPadding-sm">
            {/* <!--SECCION DE INICIO RESPONSIVE--> */}
                <section className="inicio py-4">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                            <div className="paginaPrincipal__inicio">
                                <h1 className="paginaPrincipal__titulo ">APRENDE ODONTOLOGÍA</h1>
                                <h2 className="paginaPrincipal__subtitulo noMargin">GANA CRIPTOMONEDAS</h2>
                                <p className="paginaPrincipal__introduccion">
                                    La primer plataforma de educación dental que te paga <span className="introduccion__amarillo"> crypto</span> por aprender.
                                </p>
                                <Link to="/cursos">
                                    <button className="paginaPrincipal__botonComenzar">
                                        <span className="botonComenzar__text"> COMENZAR </span>
                                    </button>
                                </Link>
                            </div>
                        </div>
                        <div className="col-12 col-xs-0 col-sm-0 col-md-6 col-lg-6 col-xl-6">
                            <div className="paginaPrincipal__dimensionImg">
                                <img src={ LogoAmarillo } alt="" className="dimensionImg animacionBounce" />
                            </div>
                        </div>
                    </div>
                </section>
            {/* <!--SECCION ¿COMO FUNCIONA? RESPONSIVE--> */}
                <section className="comoFunciona">
                    <h3 className="paginaPrincipal__subtitulo2">¿CÓMO FUNCIONA?</h3>
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-4">
                            <div className="paginaPrincipal__card">
                                <div className="header__cardInscribete">
                                    <i className="fa-solid fa-video cardInscribete__icono"></i>
                                </div>
                                <div className="body__cardInscribete">
                                    <div className="textoCard">
                                        <span className="textoCard__span"> 1. INSCRIBETE A UN CURSO </span>
                                        <p className="textoCard__parrafo"> Elige un tema que quieras aprender e inscríbete, es sencillo. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                            <div className="paginaPrincipal__card">
                                <div className="header__cardCulmina">
                                    <i className="fa-solid fa-award cardCulmina__icono"></i>
                                </div>
                                <div className="body__cardCulmina">
                                    <div className="textoCard">
                                        <span className="textoCard__span"> 2. CULMINA EL CURSO </span>
                                        <p className="textoCard__parrafo"> Debes finalizar todas las clases, sin excepción. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-4">
                            <div className="paginaPrincipal__card">
                                <div className="header__cardReclama">
                                    <img src={ BasicLogoAmarillo } alt="" className="cardReclama__img" />
                                </div>
                                <div className="body__cardReclama">
                                    <div className="textoCard">
                                        <span className="textoCard__span"> 3. RECLAMA TUS CRYPTO </span>
                                        <p className="textoCard__parrafo"> Obtendrás nuestra criptomoneda como recompensa. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            {/* <!--SECCION CURSOS POPULARES RESPONSIVE--> */}
                <section className="cursosPopulares">
                    <h3 className="paginaPrincipal__subtitulo2">CURSOS POPULARES</h3>
                    <p className="paginaPrincipal__textRecomendacion">Te recomendamos echar un vistazo a estos cursos, <br/>son los mejores.</p>
                    {
                        (cursosPopulares.length > 0) &&
                            <Cursos cursos={cursosPopulares} titulo={''} darkMode={true}/>
                    }
                    <div className="col-12">
                        <Link to="/cursos"  className="">
                            <button className="cursositem__buttonInscribir btnPrimario">
                                <p className="buttonInscribir__text">VER TODOS LOS CURSOS</p>
                                            
                            </button>
                        </Link>
                    </div>
                </section>
            {/* <!--SECCION ¿QUE ES CURSODONTIC? RESPONSIVE--> */}
                <section className="queEsCursodontic my-5">
                    <hr className="separadorPP" />
                    <h3 className="paginaPrincipal__subtitulo3">¿QUÉ ES CURSODONTIC?</h3>
                    <p className="paginaPrincipal__textGeneral">
                        
                        Somos una <span className="textBoldBlanco">empresa tecnológica y de educación</span> dirigida al área dental, y la primer plataforma que te paga <span className="textAmarillo">Denthereum</span> por aprender.
                    
                    </p>
                    <hr className="separadorPP" />
                    <div className="row">
                        <div className="col-12 col-md-4 col-lg-4 col-xl-4">
                            <div className="paginaPrincipal__infoCursodontic">
                                <div className="infoCursodontic">
                                    <div className="infoCursodontic__circulo internacionalCirculo__color">
                                        <img src={ Internacional } alt="" className="infoCursodontic__img" />
                                    </div>
                                </div>
                                <h4 className="infoCursodontic__subtituloGeneral internacionalText__color">Más de 15,000 dentistas</h4>
                                <p className="infoCursodontic__parrafoGeneral">
                                    Somos una comunidad presente en +15 países, entre ellos: México, Colombia, Argentina y Ecuador.
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 col-lg-4 col-xl-4">
                            <div className="paginaPrincipal__infoCursodontic">
                                <div className="infoCursodontic">
                                    <div className="infoCursodontic__circulo grabacionCirculo__color">
                                        <img src={ Grabacion } alt="" className="infoCursodontic__img" />
                                    </div>
                                </div>
                                <h4 className="infoCursodontic__subtituloGeneral grabacionText__color">Cursos producidos</h4>
                                <p className="infoCursodontic__parrafoGeneral">
                                    Todos los cursos son grabados previamente, así puedes ver el curso a tu propio ritmo desde Cursodontic
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 col-lg-4 col-xl-4">
                            <div className="paginaPrincipal__infoCursodontic">
                                <div className="infoCursodontic">
                                    <div className="infoCursodontic__circulo asequiblesCirculo__color">
                                        <img src={ PreciosAsequibles } alt="" className="infoCursodontic__img" />
                                    </div>
                                </div>
                                <h4 className="infoCursodontic__subtituloGeneral asequiblesText__color">Pagos protegidos y seguros</h4>
                                <p className="infoCursodontic__parrafoGeneral">
                                    Tus datos personales y de pago siempre estarán cifrados para tu seguridad.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            {/* <!--SECCION Y ¿QUE ES UN DENTHEREUM TOKEN? RESPONSIVE--> */}
                <section className="queEsDenthereumToken pb-5">
                    <div className="row">
                        <div className="col-12 col-lg-12 col-xl-12 d-flex justify-content-center">
                            <div className="queEsToken">
                                <h3 className="paginaPrincipal__subtituloToken">BIENVENIDO A LA WEB3</h3>
                                <div className="paginaPrincipal__logoDenthereum">
                                    <img src={ LogoAmarillo } alt="" className="logoDenthereum__img animacionBounce" />
                                </div>
                                <p className="paginaPrincipal__textGeneral">
                                    
                                    <span className="textAmarillo">Denthereum</span> es una criptomoneda desarrollada por nuestro increíble equipo de tecnología. 💛
                                    <br/>
                                    Con ella puedes apoyar al proyecto, tener precios con descuento y <span className="textBoldBlanco">cambiarlas por otras criptomonedas como Bitcoin, BNB o Ethereum.</span>
                                
                                </p>
                                <div className="paginaPrincipal__botonesToken">
                                    <Link to="/denthereum/comprar" className="botonesToken__comprar d-flex align-items-center justify-content-center">
                                        <p className="comprar__text font-weight-bold"> 
                                            Comprar Denthereum 
                                        </p>
                                    </Link>
                                    <Link to="/denthereum" className="botonesToken__aprender d-flex align-items-center justify-content-center">
                                        <p className="aprender__text"> Saber más... </p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            {/* <!--SECCION ALUMNO PREMIUM RESPONSIVE--> */}
                <section className="alumnoPremium py-5">
                    <h3 className="paginaPrincipal__subtituloPremium">Conviértete en un alumno premium</h3>
                    <p className="paginaPrincipal__textRecomendacion">Sí en verdad quieres aprender al máximo, tenemos la solución perfecta para ti.</p>

                    <div className="mb-5">
                        <Membresia login={props.login} setModalLogin={props.setModalLogin} />
                    </div>

                </section>

                
            </div>

            <Denthereum isDarkMode={true} />
        </div>
    );
}
