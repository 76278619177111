import React, { useState, useEffect, useRef } from "react";

import Modal from "react-bootstrap/Modal";

import Meta from "../../assets/icons/metamask.png";

import "./CrearCuentaModal.css";

import { validateNoNumbers } from "../../validators/validateNoNumbers";
import { validateEmail } from "../../validators/validateEmail";

import { AiOutlineEye } from "react-icons/ai";
import { AiOutlineEyeInvisible } from "react-icons/ai";

import loginSocialMedia from "./../../services/loginSocialMedia";
import SignUpSocialMedia from "./../../services/SignUpSocialMedia";

import addUserAccount from "../../services/addUserAccount";

import Swal from "sweetalert2";
import $ from "jquery";

//FIREBASE
import firebase from "../../utils/Firebase/firebase.js";
import { sendCompleteRegistration } from "../../utils/facebookPixelEvents";

function CrearCuentaModal({handleLogin, showModal, setShowModal}) {

  const [cuenta__email, setCuenta__email] = useState("");

  const [cuenta__phone, setCuenta__phone] = useState("");

  const [cuenta__nombre, setCuenta__nombre] = useState("");

  const [cuenta__apellido, setCuenta__apellido] = useState("");

  const [cuenta__especialidad, setCuenta__especialidad] = useState("");

  const [cuenta__password, setCuenta__password] = useState("");

  const [passwordShow, setPasswordShow] = useState("password");

  const auth = firebase.auth;
  const provider = new firebase.auth.GoogleAuthProvider();

  let values = new FormData();

  function onChangeEmail(e) {
    setCuenta__email(e.target.value);
  }

  function onChangePhone(e) {
    setCuenta__phone(e.target.value);
  }

  function onChangeNombre(e) {
    setCuenta__nombre(e.target.value);
  }

  function onChangeApellido(e) {
    setCuenta__apellido(e.target.value);
  }

  function onChangeEspecialidad(e) {
    setCuenta__especialidad(e.target.value);
  }

  function onChangePassword(e) {
    setCuenta__password(e.target.value);
  }

  function handleViewPassword() {
    if (
      document.querySelector("#closed-eye").classList[0] === "icon-eye" ||
      document.querySelector("#closed-eye").classList[1] === "icon-eye"
    ) {
      document.querySelector("#opened-eye").classList.add("icon-eye");
      document.querySelector("#closed-eye").classList.remove("icon-eye");
      setPasswordShow("password");
    } else {
      document.querySelector("#closed-eye").classList.add("icon-eye");
      document.querySelector("#opened-eye").classList.remove("icon-eye");
      setPasswordShow("text");
    }
  }

  function onSubmitNewUser(e) {
    e.preventDefault();
    if (validateEmail(cuenta__email)) {
      let userValues = new FormData();

      let nombreCompleto = `${cuenta__nombre} ${cuenta__apellido}`;
    //   console.log(nombreCompleto);
    //   console.log(cuenta__phone);
    //   console.log(cuenta__especialidad);
    //   console.log(cuenta__password);
    //   console.log(cuenta__email);
      userValues.append("nombre", nombreCompleto.toString());
      userValues.append("numero", cuenta__phone);
      userValues.append("especialidad", cuenta__especialidad);
      userValues.append("medioRegistro", "web");
      userValues.append("pass", cuenta__password);
      userValues.append("correo", cuenta__email);

      Swal.showLoading();

      addUserAccount(userValues).then((response) => {
        let _respuesta = JSON.parse(response);
        // console.log(_respuesta.response);

        if (_respuesta.error === "correoExistente") {
          Swal.fire({
            icon: "error",
            title: "Correo registrado",
            text: "El correo ya se encuentra registrado, verifica que esté correcto o intenta iniciar sesión.",
            showConfirmButton: true,
          });
        } else if (_respuesta.error === "usuario") {
          Swal.fire({
            icon: "error",
            title: "Usuario no creado",
            text: "Ha ocurrido un error al registrar el usuario, por favor revisa tus datos e inténtalo nuevamente.",
            showConfirmButton: true,
          });
        } else {
          try {
            _respuesta.email = cuenta__email
            setShowModal(false);
            handleLogin(_respuesta);
            sendCompleteRegistration(_respuesta.nombre,_respuesta.correo,_respuesta.numero)
          } catch (err) {}
        }
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Verifica que tu correo sea válido",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }

  return (
    <div>
      {/* <div className="text-center" onClick={handleShow}>
        <p>
          ¿No tienes cuenta?{" "}
          <span className="modalInicioSesion_body-password">
            Regístrate aquí
          </span>
        </p>
      </div> */}

      <Modal show={showModal} onHide={setShowModal}>
        <Modal.Header
          className="modalCrearCuenta_header titulo bebas"
          closeButton
        >
          <p className="bebas modalCrearCuenta_header-titulo">CREAR CUENTA</p>
        </Modal.Header>

        <Modal.Body className="modalCrearCuenta_body">

          <div className="modalCrearCuenta_body-form p-3">
            <p>Registrate escribiendo los siguientes datos:</p>

            <form onSubmit={onSubmitNewUser} id="add-user">
              <div className="form-group mt-3">
                <input
                  type="email"
                  className="form-control"
                  id=""
                  aria-describedby="email"
                  placeholder="Email"
                  onChange={onChangeEmail}
                  value={cuenta__email}
                  required
                />
              </div>

              <div className="form-group my-3">
                <input
                  type="tel"
                  className="form-control"
                  id=""
                  onKeyPress={validateNoNumbers}
                  aria-describedby="telefono"
                  placeholder="Celular"
                  maxLength="10"
                  onChange={onChangePhone}
                  value={cuenta__phone}
                />
              </div>

              <div className="row mb-3">
                <div className="col-6">
                  <input
                    type="text"
                    className="form-control"
                    id=""
                    aria-describedby="nombre"
                    placeholder="Nombre"
                    onChange={onChangeNombre}
                    value={cuenta__nombre}
                    required
                  />
                </div>

                <div className="col-6">
                  <input
                    type="text"
                    className="form-control"
                    id=""
                    aria-describedby="Apellido"
                    placeholder="Apellido"
                    onChange={onChangeApellido}
                    value={cuenta__apellido}
                    required
                  />
                </div>
              </div>

              <div className="form-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  id=""
                  aria-describedby="especialidad"
                  placeholder="Especialidad"
                  onChange={onChangeEspecialidad}
                  value={cuenta__especialidad}
                  required
                />
              </div>

              <div className="form-group mb-3 d-flex">
                <input
                  type={passwordShow}
                  className="form-control"
                  id=""
                  placeholder="Contraseña"
                  onChange={onChangePassword}
                  required
                />
                <span
                  className="m-2 show-password-icon"
                  onClick={handleViewPassword}
                >
                  <AiOutlineEye id="opened-eye" className="icon-eye fs-3" />
                  <AiOutlineEyeInvisible id="closed-eye" className="fs-3" />
                </span>
              </div>

              <p className="text-center">
                Acepto los términos y condiciones estipulados
                <span className=" my-3">
                  <a
                    href="https://www.iubenda.com/privacy-policy/85495804/full-legal"
                    target="_blank"
                    className="modalCrearCuenta_body-terminos"
                  >
                    Da click aquí
                  </a>
                </span>
              </p>

              <button
                type="submit"
                className="btn btn-light btn-lg w-100 py-2 modalCrearCuenta_body-botonCompra my-2"
              >
                REGISTRARME
              </button>
            </form>
          </div>
        </Modal.Body>

        {/* <Modal.Footer className="modalCrearCuenta_footer justify-content-center">
          <div className="text-center" onClick={switchToModalLogin}>
            <p>
              ¿Ya tienes una cuenta?{" "}
              <span className="modalCrearCuenta_body-terminos">
                Inicia sesión aquí
              </span>
            </p>
          </div>
        </Modal.Footer> */}
      </Modal>
    </div>
  );
}

export { CrearCuentaModal };
