import React, { useState } from 'react';
import './courseQualification.css';

import Rate from "../courseRate/Rate";
import StarIcon from "@mui/icons-material/Star";
import Swal from 'sweetalert2';
import $ from "jquery";

import addCourseReview from '../../services/addCourseReview';

const CourseQualification = (props) => {

    let idUsuario = localStorage.getItem("idUsuario");

    const [rating, setRating] = useState(0);
    const [contenido, setContenido] = useState('');

    function onChangeContenido(e) {
        setContenido(e.target.value);
    }

    function addReview(idCurso,contenido,rating) {

        console.log(idUsuario);
        console.log(idCurso);
        console.log(contenido);
        console.log(rating);

        let formData = new FormData();
        
        formData.append('idUsuario', idUsuario);
        formData.append('idCurso', idCurso);
        formData.append('contenido', contenido);
        formData.append('calificacion', rating);

        if(contenido === '' || rating === 0) {
            Swal.fire({
                icon: 'warning',
                title: 'Verifica que los campos esten completos',
                text: 'Revisa tu calificación y que el comentario no se encuentre vacío.',
            });
        } else {
            addCourseReview(formData)
            .then(response => {
                let _respuesta = JSON.parse(response);
                
                if(_respuesta.response === 'success'){
                    Swal.fire({
                        icon: 'success',
                        text: 'Tu comentario se ha agregado exitosamente.',
                        showConfirmButton: false,
                        timer: 1500
                    });

                    $(`#qualificationModal${props.idCurso}`).hide();
                    $(".modal-backdrop").hide();

                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Ocurrió un error',
                        text: 'Revisar la conexión a internet e inténtalo de nuevo.',
                        showConfirmButton: false,
                        timer: 1500
                    });
                }
            })
            .catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error del servidor',
                    text: 'Inténtalo de nuevo en unos momentos.',
                    showConfirmButton: false,
                    timer: 1500
                })
            });
        }
    }

  return (
    <>
        <div className="modal fade text-center" id={`qualificationModal${props.idCurso}`} tabindex="-1" role="dialog" aria-labelledby={`qualificationModal${props.idCurso}Label`} aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-body p-3">
                        <div className="col-12 d-flex justify-content-end exit-icon-modal">
                            <button type="button" className="close fs-2" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="col-12">
                            <p className="modal-title text-center mt-3" id={`qualificationModal${props.idCurso}Label`}>¿Cuántas estrellas le das al curso?</p>
                            <Rate rating={rating} onRating={(rate) => setRating(rate)} />
                        </div>
                        <div className="col-12 mt-2 mb-4">
                            <textarea id="review-course" name="review" required cols="30" rows="5" placeholder="Deja un comentario" onChange={onChangeContenido}/>
                        </div>
                    </div>
                    <div className="modal-footer justify-content-center">
                        {/* <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button> */}
                        <button type="button" className="btn qulify-button-my-courses-card d-flex justify-items-center align-items-center fs-5" onClick={() => addReview(props.idCurso,contenido,rating)}>
                            <StarIcon className="star-icon-modal fs-5" />Calificar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default CourseQualification;