import React from 'react';

import './MarketplaceGeneral.css';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { Denthereum } from '../Denthereum/Denthereum.js';
import { CursosItem } from '../CursosItem/CursosItem.js';
import { MaterialItem } from '../MaterialItem/MaterialItem.js';
import { MetodosPago } from '../MetodosPago/MetodosPago.js';
import { Membresia } from '../Membresia/Membresia';

import Swal from 'sweetalert2'

import getRandomCourses from "./../../services/getRandomCourses.js";
import getRandomProducts from "./../../services/getRandomProducts.js";

import config from '../../config.js';

import $ from 'jquery';

import ReactLoading from 'react-loading';
import { useHistory } from 'react-router';

const imagenesCursos = [
    { id: 1, url: 'https://cursodontic.com/asset/img/tiendas/proplay/VERDE_ELECTRICO1.jpg' },
    { id: 2, url: 'https://cursodontic.com/asset/img/tiendas/proplay/PROTECTORES_MIXTO.jpg' },
    { id: 3, url: 'https://cursodontic.com/asset/img/tiendas/proplay/UVA.jpg' },
    { id: 4, url: 'https://cursodontic.com/asset/img/tiendas/proplay/AZUL_TRASLUCIDO1.jpg' },
];

function MarketplaceGeneral(props) {

    const [cursos, setCursos] = React.useState([]);
    const [productos, setProducts] = React.useState([]);
    const history = useHistory()

    React.useEffect(() => {
        getCursos()
        getProductos()
    }, []);

    function getCursos() {
        getRandomCourses().then(response => {
            let _respuesta = JSON.parse(response);
            if (_respuesta.response === "error") {
                Swal.fire(
                    'Error al cargar los cursos',
                    'Inténtalo de nuevo recargando la página.',
                    'error'
                )
            } else {
                setCursos(_respuesta.cursos);
            }

        }).catch((error) => {
            // Swal.fire(
            //     'Error del servidor',
            //     'Inténtalo de nuevo en unos momentos.',
            //     'error'
            // )
        })
    }

    function getProductos() {
        getRandomProducts().then(response => {
            let _respuesta = JSON.parse(response);
            console.log(_respuesta);
            if (_respuesta.response === "error") {
                Swal.fire(
                    'Error al cargar los productos',
                    'Inténtalo de nuevo recargando la página.',
                    'error'
                )
            } else {
                setProducts(_respuesta.productos);
            }

        }).catch((error) => {
            Swal.fire(
                'Error del servidor',
                'Inténtalo de nuevo en unos momentos.',
                'error'
            )
        })
    }

    const state = {
        responsive: {
            0: {
                items: 1,
            },
            576: {
                items: 2,
            },
            768: {
                items: 2,
            },
            992: {
                items: 3,
            },
            1200: {
                items: 4,
            },
        },
    }

    const realizarBusqueda = (e) => {

        e.preventDefault();

        let busqueda = $("#busquedaMarketplace").val();

        if (busqueda.trim().length > 0) {

            // window.location.replace(config.urlWeb + "resultados/" + busqueda);
            history.push('/resultados/'+busqueda)

        }
    }

    return (

        <>
            {productos.length === 0 && cursos.length === 0 ?

                <div className="circularBarProgressIndicator">
                    <ReactLoading type="spin" color="#F65F5D" height={'50px'} width={'50px'} className="circularBarProgressIndicatorItem" />
                </div> :
                <div className="marketplace">

                    <div className="marketplace-gradient container-fluid">

                        <div className="container">
                            <p className="bebas marketplace-titulo">MARKETPLACE</p>
                            <p className="marketplace-subtitulo mb-4">Compra cursos, materiales o instrumentales</p>

                            <form id="formularioBusqueda" onSubmit={realizarBusqueda}>
                                <div className="form-group">
                                    <input type="text" className="form-control" id="busquedaMarketplace" placeholder="Buscar" autoComplete="off" />
                                </div>
                            </form>
                        </div>

                    </div>


                    <div className='container'>

                        <p className="bebas mt-5 mb-2 mx-2 titulos">CURSOS</p>

                        {
                            cursos.length >= 1 &&
                            <OwlCarousel className="owl-theme"
                                items="5"
                                margin={10}
                                autoplay={false}
                                loop={true}
                                dots={true}
                                touchDrag={true}
                                animateIn={true}
                                responsive={state.responsive}
                                center={false}
                                stagePadding={30}
                            >

                                {cursos.length >= 1 && cursos.map(
                                    curso => (
                                        <div key={curso.idCurso} className="item">
                                            <CursosItem
                                                id={curso.idCurso}
                                                titulo={curso.tituloCurso}
                                                precio={curso.precio}
                                                divisa={curso.divisa}
                                                url={curso.url}
                                                ponente={curso.ponente}
                                                categoria={curso.categoria}
                                                rate={curso.rate}
                                                alumnos={curso.alumnos}
                                                opiniones={curso.numOpiniones}
                                                urlCorta={curso.urlCorto}
                                                
                                            />
                                        </div>
                                    )
                                )}

                            </OwlCarousel>
                        }

                        <p className="bebas mt-5 mb-2 mx-2 titulos">Materiales</p>

                        {
                            productos.length >= 1 &&
                            <OwlCarousel className="owl-theme"
                                items="5"
                                autoplay={false}
                                loop={true}
                                dots={true}
                                touchDrag={true}
                                animateIn={true}
                                responsive={state.responsive}
                                center={false}
                                stagePadding={30}
                                margin={10}
                            >

                                {productos.length >= 1 && productos.map(
                                    producto => (
                                        <div key={producto.idProducto} className="item">
                                            <MaterialItem
                                                tienda={producto.tienda}
                                                idProducto={producto.idProducto}
                                                nombre={producto.nombre}
                                                imagen={producto.imagen}
                                                precio={producto.precio}
                                                divisa={producto.divisa}
                                                precioInternacional={producto.precioInternacional}
                                                flag={producto.flag}
                                                rate={producto.rate}
                                                calificaciones={producto.calificaciones}
                                                url={producto.url}
                                            />
                                        </div>
                                    )
                                )}

                            </OwlCarousel>
                        }

                        <hr className="mt-5" />

                        {/* <MetodosPago /> */}

                        <Membresia login={props.login} setModalLogin={props.setModalLogin} />

                        <Denthereum />

                    </div>

                </div>

            }
        </>

    );
}

export { MarketplaceGeneral };