import React, {useEffect } from "react";
import InicioDenthereum from "../components/InicioDenthereum"
import SubtituloDenthereum from "../components/SubtituloDenthereum"
import QueEsperarDenthereum from '../components/QueEsperarDenthereum'
import QuePuedoHacerDenthereum from '../components/QuePuedoHacerDenthereum';
import AprendeYGanaDenthereum from '../components/AprendeYGanaDenthereum';
import GuiaRapidaDenthereum from '../components/GuiaRapidaDenthereum';
import { MetodosPago } from './../components/MetodosPago/MetodosPago'
import { Denthereum } from './../components/Denthereum/Denthereum'

import "./../assets/css/PaginaPrincipal.css"

export default function DenthereumLanding() {

  useEffect(() => {
    let titulo = document.getElementById("tituloWeb");
    titulo.innerHTML = 'Cursodontic | Denthereum';
    window.scrollTo(0, 0);
  }, []);


  return (

    <React.Fragment>

      <InicioDenthereum />

      <QueEsperarDenthereum />

      <QuePuedoHacerDenthereum />

      <AprendeYGanaDenthereum />

      <GuiaRapidaDenthereum />

      <hr />

      <Denthereum />

    </React.Fragment>

  )
}

