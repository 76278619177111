import React, { useState, useEffect } from 'react';
import '../components/purchaseReceipt/purcahseReceipt.css';

import { BsCheckCircle } from 'react-icons/bs';
import { GoChevronRight } from 'react-icons/go';
import { AiOutlineClose } from 'react-icons/ai';
import { Link, useParams } from 'react-router-dom';

const PurchaseReceipt = () => {

    let idUsuario = localStorage.getItem('idUsuario');
    let usuarioMembresia = localStorage.getItem('statusMembresia');
    const { tipo_membresia } = useParams();

    useEffect(() => {
        window.scrollTo(0, 0);
        if(usuarioMembresia === '0' || usuarioMembresia === null || usuarioMembresia === undefined ) {
            localStorage.setItem('statusMembresia','1')
        }
    } , []);

    

  return (
    <>
        <div className="container-fluid compra-realizada-container p-0">
            <Link to="/" >
                <div className="exit-icon p-2 d-flex justify-content-end">
                    <AiOutlineClose />
                </div>
            </Link>
            <section className="container text-center py-2">
                <div className="check-icon d-flex justify-content-center">
                    <BsCheckCircle />
                </div>
                <div className="col-12 my-3">
                    <p className="text-white-color font-semibold fs-4">Tu suscripción ha sido activada con éxito</p>
                </div>
            </section>
        </div>
        <div className="container py-4">
            <section className="container card-receipt recipt-container">
                <div className="col-12 my-2">
                    <p className="font-semibold fs-5">Tipo de membresía:</p>
                </div>
                <div className="row pb-2">

                    <hr className="my-2"/>
                    <div className="col-3 text-center font-lg">
                        🎖
                    </div>
                    <div className="col-7 d-flex align-items-center">
                        <p>Suscripción {tipo_membresia}</p>
                    </div>
                    <div className="col-2 right-icon d-flex align-items-center justify-content-center">
                        <GoChevronRight className="align-middle"/>
                    </div>

                </div>
            </section>

        </div>
    </>
  )
}

export default PurchaseReceipt;