import React from 'react';
import { Grid, Box } from '@mui/material';

import MyCoursesCard from '../components/myCoursesCard/MyCoursesCard';
import RecentlyPlayed from '../components/recentlyPlayedCard/RecentlyPlayed';

import { FaVideo } from 'react-icons/fa';
import ReactLoading from 'react-loading';

import { Link } from 'react-router-dom';

import '../components/myCourses/myCourses.css';

import getCourses from '../services/getCourses';

import Swal from 'sweetalert2';

import config from '../config';

const MyCourses = () => {

    let idUsuario = localStorage.getItem('idUsuario');

    const [courses, setCourses] = React.useState([]);
    const [loading, setLoading] = React.useState(true)

    React.useEffect(() => {
        let titulo = document.getElementById("tituloWeb");
        titulo.innerHTML = 'Mis cursos | Cursodontic';
        getUserCourses(idUsuario);
        window.scrollTo(0, 0);
    },[]);
    
    function getUserCourses(idUsuario) {

        let formData = new FormData();

        formData.append('idUsuario', idUsuario);

        let url = config.urlApi+'controladores/misCursos.app.php';

        getCourses(formData,url)
            .then(response => {
                setLoading(false)
                console.log({response})
                let _respuesta = JSON.parse(response);
                console.log({_respuesta});
                setCourses(_respuesta);
            })
            .catch(error => {
            // Swal.fire(
            //     'Error del servidor',
            //     'Inténtalo de nuevo en uno momentos.',
            //     'error'
            // )
            })

    }

  return (
    <>  

        {(loading && courses.length === 0) &&
            <div className="circularBarProgressIndicator">
                <ReactLoading type="spin" color="#F65F5D" height={'50px'} width={'50px'} className="circularBarProgressIndicatorItem" />
            </div>
        }

        {(courses.length > 0 && courses !== '' && !loading) &&
            <div className="container">
                <Grid container spacing={2} pl={1} pr={1}>
                    <Grid item xs={12} maxWidth="lg">
                        <Box maxWidth="lg">
                            <h1 className="font-bebas my-courses-title">MIS CURSOS</h1>
                        </Box>
                    </Grid>
                    <Grid item xs={12} mt={3} className="d-flex justify-content-center align-items-center">
                        <RecentlyPlayed />
                    </Grid>
                    <Grid item xs={12} pb={1}>
                    {courses.map(
                            (course) => (
                                <MyCoursesCard course={course}/>
                            )
                        )} 
                    </Grid>
                    <Grid item xs={12} pt={3} pb={3}>
                        <Link to="/cursos"><button className="btn btn-sm btn-all-courses d-flex align-items-center justify-content-center" type="button">
                            <span className="fs-5 mx-2 d-flex align-items-cente"><FaVideo /></span>Explorar más cursos</button>
                        </Link>
                    </Grid>
                </Grid>
            </div> 
        }
        
        {
            (!loading && courses.length === 0) &&
            <div className="text-center mt-4 divPrincipal" id="noExistenProductos">
                <span className="iconoCarritoCompra">
                    🧐
                </span>
                <h2 className="my-3">No tienes cursos</h2>
                <p className="text-muted my-4">Visita nuestra tienda y agrega algo para continuar</p>
                <Link to="/marketplace" className="btn btn-rojo btn-lg my-3">
                    Explorar ahora
                </Link>
            </div>
        }
    </>
  )
}

export default MyCourses;