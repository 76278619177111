import React from 'react';
import './Membresia.css';
import Check from '../../assets/images/check.png';
import Swal from 'sweetalert2';
import getMembershipPricing from './../../services/getMembershipPricing'
import stripeCreateSession from './../../services/stripeCreateSession'
import { loadStripe } from "@stripe/stripe-js";
import ReactLoading from 'react-loading';

function Membresia(props){

    let idUsuario = localStorage.getItem("idUsuario");
    let statusMembresia = localStorage.getItem("statusMembresia");
    const stripePromise = loadStripe(process.env.REACT_APP_KEY_STRIPE);
    const [currency, setCurrency] = React.useState('MXN');
    const [precioAnual, setPrecioAnual] = React.useState(100);
    const [loading, setLoading] = React.useState(false)

    React.useEffect(()=>{
        getPricing()
    },[])

    function getPricing() {

        let formData = new FormData();

        formData.append("idUsuario", idUsuario);
        formData.append("statusMembresia", statusMembresia);

        getMembershipPricing(formData)
            .then((response) => {
                // console.log(response)
                let _respuesta = JSON.parse(response);
                console.log(_respuesta)
                if(_respuesta.response === "success") {

                    setCurrency(_respuesta.mensual['divisa']);
                    let precioAuxAnual = _respuesta.anual['precioMembresiaAnual'];
                    setPrecioAnual(precioAuxAnual);

                } else {
                    Swal.fire(
                        "Error del servidor",
                        "Inténtalo de nuevo en uno momentos.",
                        "error"
                    );
                }
            })
            .catch((error) => {
                Swal.fire(
                    "Error del servidor",
                    "Inténtalo de nuevo en uno momentos.",
                    "error"
                );
                console.log(error);
            });

    }

    const handleSubirAPremium = ()=>{
        if(props.login){
            setLoading(true)
            let data = {
                tipoMembresiaSeleccionada: 'anual',
                idUsuario: localStorage.getItem('idUsuario')
            }
            stripeCreateSession({values:data})
                .then(async respuesta=>{
                    console.log(respuesta);
                    var _respuesta = JSON.parse(respuesta);
                    var sessionId = _respuesta.id;
                    const stripe = await stripePromise
                    return stripe.redirectToCheckout({ sessionId: sessionId });
                })
        } else {
            setLoading(false)
            props.setModalLogin(true)
        }
    }

    return(
        <div className={`container px-0 mt-4 membresiaContainer ${statusMembresia === '1' && 'hidden'}`}>

            <div className="membresiaTop">

                <div className="membresiaTop_titulo bebas">
                    <i className="fa fa-award"></i>
                    <span>MEMBRESÍA</span>
                    <span className="membresiaTop_amarillo"> PREMIUM</span>
                </div>

                <div className="text-center membresiaTop_ahorro">
                    <span>Ahorra ${parseFloat(precioAnual*0.54).toFixed(0)} {currency}</span>
                </div>

                <div className='d-flex justify-content-center my-4'>
                    <span className='mx-2'>
                        Pago único:
                    </span>
                    <span className="mx-1 membresiaTop_precio"> $ <span>{precioAnual}</span> {currency}/año</span>
                </div>

                <button 
                    type="button" 
                    className="btn btn-light btn-lg w-100 py-3 d-flex justify-content-center align-items-center membresiaTop_botonSuscribirme my-2"
                    onClick={handleSubirAPremium}
                    disabled={loading}
                >
                    {
                        loading &&
                        <ReactLoading 
                            type="spin" 
                            color="#fff" 
                            height={'24px'} 
                            width={'24px'}
                            className="circularBarProgressIndicatorItem" 
                        />
                    }
                
                    {
                        !loading && '¡SUBE A PREMIUM AHORA!'
                    }
                </button>

            </div>

            <div className="membresia_flag">
                <i className="fa fa-rocket mx-2"></i>
                <span>Impulsa tu carrera con acceso a todos los cursos</span>
            </div>

            <div className="membresiaEnd">

                <div className="d-flex align-items-center">

                    <img src={Check} alt="check"/>

                    <span>¡Ahorra pagando el plan anual!</span>

                </div>

                <div className="d-flex align-items-center">

                    <img src={Check} alt="check"/>

                    <span>Todos los beneficios del curso individual</span>

                </div>

                <div className="d-flex align-items-center">

                    <img src={Check} alt="check"/>

                    <span>Acceso completo a todo Cursodontic <p className="text-verde">(+50 cursos)</p></span>

                </div>

                <div className="d-flex align-items-center">

                    <img src={Check} alt="check"/>

                    <span>Sin límite de reproducciones diarias</span>

                </div>

                <div className="d-flex align-items-center">

                    <img src={Check} alt="check"/>

                    <span>Contenido siempre disponible</span>

                </div>

                <div className="d-flex align-items-center">

                    <img src={Check} alt="check"/>

                    <span>Certificado de culminación</span>

                </div>

                <div className="d-flex align-items-center">

                    <img src={Check} alt="check"/>

                    <span>Sin permanencia obligatoria, cancela cuando quieras</span>

                </div>

                <div className="d-flex align-items-center">

                    <img src={Check} alt="check"/>

                    <span>Gana cripto denthereum al culminar 10 cursos</span>

                </div>

                <div className="d-flex align-items-center">

                    <img src={Check} alt="check"/>

                    <span>Disponible en cualquier dispositivo</span>

                </div>
                
            </div>

        </div>
    );
}

export {Membresia};