import React, { useState, useEffect } from "react";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import StarIcon from "@mui/icons-material/Star";

import "./myCoursesCard.css";

import $ from "jquery";

import CourseProgress from "../courseProgress/CourseProgress";

import getLastClass from "../../services/getLastClass";
import addViewedCourse from "../../services/addViewedCourse";

import { Link } from "react-router-dom";

import CourseQualification from '../../components/courseQualification/CourseQualification';

// import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
// import { Grid, Box, Card, CardContent, Button, CardMedia } from '@mui/material';
// import imgCourse from '../../assets/coursesImages/protectores-personalizados.jpg';

const MyCoursesCard = ({ course }) => {
  let idUsuario = localStorage.getItem("idUsuario");

  const [lastClass, setLastClass] = useState(1);
  const [nombreCursoUrl, setNombreCursoUrl] = useState();

  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);

  useEffect(() => {
    if (!(course.opinionCurso === "")) {
      $(`.qulify-button-my-courses-${course.idCurso}`).addClass("hidden");
    }
    getLastCourseData();

    let url = course.urlCurso.split('/');
    setNombreCursoUrl(url[3]);
  });

  function getLastCourseData() {

    let formData = new FormData();

    formData.append('idUsuario', idUsuario);
    formData.append('idCurso', course.idCurso);
    formData.append("accion", 'insertarCursoVisto');

    getLastClass(formData).then(response => {
      let _respuesta = JSON.parse(response);
      // console.log(_respuesta.lastClass);
      if(_respuesta.response === 'success'){
        setLastClass(_respuesta.lastClass);
      }
    })
    .catch(error => {
        console.log(error);
    })
  }

  function addLastViewedCourse(idCurso) {
    let formData = new FormData();

    formData.append('idUsuario', idUsuario);
    formData.append('idCurso', idCurso);
    formData.append("accion", 'insertarCursoVisto');

    addViewedCourse(formData)
    .then((responseAdd) => {
      let _respuestaAdd = JSON.parse(responseAdd);
      // console.log(_respuestaAdd);
    })
  }

  return (
    <div
      className="my-courses-card-container container mt-1 mb-4"
      key={course.idCurso}
    >
      <div className="card progress-card my-courses-card-smaller-devices">
        <img
          className="card-img-top"
          src={course.fotoCurso}
          alt="Card image cap"
        />
        <div className="card-body">
          <p>{course.tituloCurso}</p>
          <div className="d-flex my-3">
            <div className="col-4 justify-content-start" onClick={addLastViewedCourse(course.idCurso)}>
              <Link to={`/mis-cursos/${nombreCursoUrl}/${lastClass}`}>
                <button className="btn play-button-my-courses-card d-flex justify-items-center">
                  <PlayCircleIcon className="play-icon" />
                  <span className="font-regular">Ver curso</span>
                </button>
              </Link>
            </div>
            <div
              className={`col-8 justify-content-start qulify-button-my-courses-${course.idCurso}`}
              id="qualify-course"
            >
              <button type="button" className="btn qulify-button-my-courses-card d-flex justify-items-center" data-toggle="modal" data-target={`#qualificationModal${course.idCurso}`} >
                <StarIcon className="star-icon" />
                <span className="font-regular">Calificar curso</span>
              </button>
              {/* <CourseQualification idCurso={course.idCurso} nombre={course.tituloCurso}/> */}

            </div>
          </div>
          <CourseProgress progress={course.promedioVisto} />
        </div>
      </div>

      <div
        className="card progress-card my-courses-card-big-devices"
        key={course.idCurso}
      >
        <div className="row">
          <div className="col-sm-4 col-md-4 col-lg-3 col-xl-3 divCardImg">
            <img
              className="card-img-top align-center"
              src={course.fotoCurso}
              alt="Card image cap"
            />
          </div>
          <div className="col-sm-8 col-md-8 col-lg-9 col-xl-9 mb-3">
            <div className="card-body">
              <p>{course.tituloCurso}</p>
              <div className="d-flex my-3">
                <div className="col-md-3 col-lg-2 justify-content-start" onClick={addLastViewedCourse(course.idCurso)}>
                  <Link to={`/mis-cursos/${nombreCursoUrl}/${lastClass}`}>
                    <button className="btn play-button-my-courses-card d-flex justify-items-center">
                      <PlayCircleIcon className="play-icon" />
                      <span className="font-regular">Ver curso</span>
                    </button>
                  </Link>
                </div>
                <div
                  className={`col-md-9 col-lg-10 justify-content-start qulify-button-my-courses-${course.idCurso}`}
                  id="qualify-course"
                >

                <button type="button" className="btn qulify-button-my-courses-card d-flex justify-items-center" data-toggle="modal" data-target={`#qualificationModal${course.idCurso}`} >
                  <StarIcon className="star-icon" />
                  <span className="font-regular">Calificar curso</span>
                </button>

                </div>
              </div>
              <CourseProgress progress={course.promedioVisto} />
            </div>
          </div>
        </div>
      </div>
      <CourseQualification idCurso={course.idCurso} nombre={course.tituloCurso}/>

    </div>
  );
};

export default MyCoursesCard;
