import React, { Component, useState, useEffect } from "react";
import ReactPlayer from "react-player";
import "./videoPlayer.css";

import classViewed from "../../services/addClassViewed";

function VideoPlayer(props) {

  const [currentTime, setCurrentTime] = useState(0);

  useEffect(() => {
    addClassViewed(currentTime,props.idClase,props.idUsuario);
  }, [currentTime]);

  function addClassViewed(duration,idClase,idUsuario) {
    
    let percentage = duration * 100;
    
    if(percentage >= 90){
      
      let formData = new FormData();

      formData.append("idClase",idClase);
      formData.append("idUsuario",idUsuario);

      classViewed(formData).then(response => {
        
        let _respuesta = JSON.parse(response);

        if(_respuesta.response === 'success'){
          console.log("success");
        }

      })
      .catch(error => {
          console.log(error);
      })
      
    }
  }

  return !props.light ? (
    <div className="d-flex justify-content-center">
      <ReactPlayer
        url={props.resolutions}
        height="auto"
        width="100%"
        controls={true}
        pip={true}
        onProgress={(progress) => {
          setCurrentTime(progress.played);
        }}
      />
    </div>
  ) : (
    <div className="d-flex justify-content-center video-player">
      <ReactPlayer
        url={props.resolutions}
        light={props.light}
        height="auto"
        width="100%"
        controls={true}
        pip={true}
        onProgress={(progress) => {
          setCurrentTime(progress.played);
        }}
      />
    </div>
  );
}

{
  /* <div className="d-flex justify-content-center video-player">
  <ReactPlayer url={props.resolutions} light={props.light} height="auto" width="100%" controls={true} pip={true}/>
</div> */
}

export default VideoPlayer;
